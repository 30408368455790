<template>
    <div class="page-container">
        <div class="login-box">
            <h5>Family Login</h5>
            <p>Enter your credentials below to enter the family portal.</p>
            <div class="credential">
                <label for="email">Username</label>
                <input type="text" name="email" placeholder="Username..." v-model="email">
            </div>
            <div class="credential">
                <label for="pass">Password</label>
                <input type="password" name="pass" placeholder="Password..." v-model="password">
            </div>
            <button class="login-btn" @click="login">Login</button>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            email: '',
            password: '',
            tempPass: 'FamilyAdmin23$',
            emailList: ['aswdesign@icloud.com', 'lanewsome@aol.com', 'cmcm267@gmail.com']
        }
    },
    methods: {
        login() {
            if (this.emailList.includes(this.email)) {
                if (this.password === this.tempPass) {
                    this.$store.commit('setUsername', this.email)
                    this.$router.push('/Family-Portal')
                }
            }
        }
    },
    created() {
        if (JSON.parse(localStorage.getItem('username'))) {
            this.$router.push('/Family-Portal')
        }
    }
}
</script>

<style scoped>
.page-container {
    width: 100vw;
    height: 100dvh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-family: 'Inter', sans-serif;
    box-sizing: border-box;
    padding: 0 2em;
}

.login-box {
    width: 30%;
    padding: 1em;
    background-color: #fff;
    border: 1px solid;
    border-radius: 5px;
    box-shadow: rgb(0 0 0 / 16%) 0px 3px 6px, rgb(0 0 0 / 23%) 0px 3px 6px;
    box-sizing: border-box;
}

.login-box h5 {
    margin: 0;
    margin-bottom: .25em;
    font-size: 1.5em;
    font-weight: normal;
}

.login-box p {
    margin: 0;
    color: #6c757d;
    margin-bottom: 2em;
}

.credential {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    margin-bottom: 1em;
}

.credential label {
    margin-bottom: .5em;
}

.credential input {
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    border-radius: 5px;
    padding: .5em;
    font-size: 1.2em;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid;
}

.credential input:focus {
    outline: 0;
}

.login-box button {
    border: 1px solid;
    width: 100%;
    font-family: inherit;
    padding: 1em;
    margin-top: 1em;
    background-color: #38413f;
    color: #fff;
    font-size: 1em;
    border-radius: 5px;
    cursor: pointer;
}

@media screen and (max-width: 1500px) {
    .login-box {
        width: 70%;
    }
}

@media screen and (max-width: 800px) {
    .login-box {
        width: 100%;
    }
}
</style>