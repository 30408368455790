<template>
  <div class="container">
    <div class="landing-container">
      <section class="landing-area">
        <div class="timeline-container">
          <div class="point-in-time">
            <div class="point-container" @click="showTimelineText('1785')">
              <div class="point">
                <div class="selected-point" v-show="date === '1785'"></div>
              </div>
              <p class="point-text" :class="date === '1785' ? 'selected' : ''">1785: <span class="point-desc">The
                  Begining</span></p>
            </div>
            <div class="time-line"></div>
          </div>
          <div class="point-in-time">
            <div class="point-container" @click="showTimelineText('1810')">
              <div class="point">
                <div class="selected-point" v-show="date === '1810'"></div>
              </div>
              <p class="point-text" :class="date === '1810' ? 'selected' : ''">1810: <span class="point-desc">First
                  Born</span></p>
            </div>
            <div class="time-line"></div>
          </div>
          <div class="point-in-time">
            <div class="point-container" @click="showTimelineText('1837')">
              <div class="point">
                <div class="selected-point" v-show="date === '1837'"></div>
              </div>
              <p class="point-text" :class="date === '1837' ? 'selected' : ''">1837: <span
                  class="point-desc">Marriage</span></p>
            </div>
            <div class="time-line"></div>
          </div>
          <div class="point-in-time">
            <div class="point-container" @click="showTimelineText('1865')">
              <div class="point">
                <div class="selected-point" v-show="date === '1865'"></div>
              </div>
              <p class="point-text" :class="date === '1865' ? 'selected' : ''">1865: <span class="point-desc">Land</span>
              </p>
            </div>
            <div class="time-line"></div>
          </div>
          <div class="point-in-time">
            <div class="point-container" @click="showTimelineText('1869')">
              <div class="point">
                <div class="selected-point" v-show="date === '1869'"></div>
              </div>
              <p class="point-text" :class="date === '1869' ? 'selected' : ''">1869: <span
                  class="point-desc">Church</span></p>
            </div>
            <div class="time-line"></div>
          </div>
          <div class="point-in-time">
            <div class="point-container" @click="showTimelineText('1900')">
              <div class="point">
                <div class="selected-point" v-show="date === '1900'"></div>
              </div>
              <p class="point-text" :class="date === '1900' ? 'selected' : ''">1900: <span class="point-desc">Fruits of
                  Labor</span></p>
            </div>
            <div class="time-line"></div>
          </div>
          <div class="point-in-time">
            <div class="point-container" @click="showTimelineText('1988')">
              <div class="point">
                <div class="selected-point" v-show="date === '1988'"></div>
              </div>
              <p class="point-text" :class="date === '1988' ? 'selected' : ''">1988: <span
                  class="point-desc">Reunion</span></p>
            </div>
          </div>
        </div>
      </section>
      <section class="landing-area">
        <div class="tree-card">
          <img :src="require(`../assets/${treeImg}`)">
          <p class="tree-name">{{ treeName }}</p>
          <p class="tree-date">{{ treeDate }}</p>
          <p class="tree-text">{{ treeText }}</p>
          <router-link to="/Our-Story">
            <button>Learn more</button>
          </router-link>
          <div class="tree-btns">
            <p :class="treeName === 'Wright Jenkins' ? 'selected' : ''" @click="setTree('wright')">Wright</p>
            <p :class="treeName === 'Mary Jenkins Mitchell' ? 'selected' : ''" @click="setTree('mary')">Mary</p>
            <p :class="treeName === 'Charles Jenkins' ? 'selected' : ''" @click="setTree('charles')">Charles</p>
            <p :class="treeName === 'Harriet Jenkins Mitchell' ? 'selected' : ''" @click="setTree('harriet')">Harriet</p>
            <p :class="treeName === 'Wright J. Jenkins' ? 'selected' : ''" @click="setTree('wright j')">Wright J.</p>
            <p :class="treeName === 'James Jenkins' ? 'selected' : ''" @click="setTree('james')">James</p>
            <p :class="treeName === 'Stephen Jenkins, Jr.' ? 'selected' : ''" @click="setTree('stephen jr')">Stephen Jr.
            </p>
            <p :class="treeName === 'Lawrence Jenkins' ? 'selected' : ''" @click="setTree('lawrence')">Lawrence</p>
            <p :class="treeName === 'Perry Jenkins' ? 'selected' : ''" @click="setTree('perry')">Perry</p>
            <p :class="treeName === 'Stephen Jenkins, Sr.' ? 'selected' : ''" @click="setTree('stephen')">Stephen</p>
            <p :class="treeName === 'Rachel Jenkins Hollomon' ? 'selected' : ''" @click="setTree('rachel')">Rachel</p>
          </div>
        </div>
      </section>
    </div>
    <div class="pulses">
      <div class="pulse" @click="setTree('wright')" :class="treeName === 'Wright Jenkins' ? 'active-pulse' : ''">
      </div>
      <div class="pulse" @click="setTree('mary')" :class="treeName === 'Mary Jenkins Mitchell' ? 'active-pulse' : ''">
      </div>
      <div class="pulse" @click="setTree('charles')" :class="treeName === 'Charles Jenkins' ? 'active-pulse' : ''">
      </div>
      <div class="pulse" @click="setTree('harriet')"
        :class="treeName === 'Harriet Jenkins Mitchell' ? 'active-pulse' : ''"></div>
      <div class="pulse" @click="setTree('wright j')" :class="treeName === 'Wright J. Jenkins' ? 'active-pulse' : ''">
      </div>
      <div class="pulse" @click="setTree('james')" :class="treeName === 'James Jenkins' ? 'active-pulse' : ''">
      </div>
      <div class="pulse" @click="setTree('stephen jr')"
        :class="treeName === 'Stephen Jenkins, Jr.' ? 'active-pulse' : ''"></div>
      <div class="pulse" @click="setTree('lawrence')" :class="treeName === 'Lawrence Jenkins' ? 'active-pulse' : ''">
      </div>
      <div class="pulse" @click="setTree('perry')" :class="treeName === 'Perry Jenkins' ? 'active-pulse' : ''">
      </div>
      <div class="pulse" @click="setTree('stephen')" :class="treeName === 'Stephen Jenkins, Sr.' ? 'active-pulse' : ''">
      </div>
      <div class="pulse" @click="setTree('rachel')" :class="treeName === 'Rachel Jenkins Hollomon' ? 'active-pulse' : ''">
      </div>
    </div>
    <div class="dates">
      <p>1785</p>
      <p>1838</p>
      <p>1842</p>
      <p>1845</p>
      <p>1850</p>
      <p>1851</p>
      <p>1860</p>
      <p>1861</p>
      <p>1846</p>
      <p>1810</p>
      <p>1859</p>
    </div>
    <infobox :title="infoBoxTitle" :subTitle="infoBoxSubTitle" :mainText="infoBoxMainText" :enabled="infoBoxEnabled"
      @disableInfoBox="disableInfoBox()"></infobox>
    <div class="footer">
      <div class="audio-container">
        <p class="audio-text">Now Listening: {{ audioText }}</p>
        <div class="audio-box">
          <div class="controls">
            <img src="@/assets/play.png" @click="playAudio()" v-show="audio === 'pause'">
            <img src="@/assets/pause.png" @click="pauseAudio()" v-show="audio === 'play'">
          </div>
          <div class="playback">
            <div class="playtime">
              <div class="playbar" ref="playbar"></div>
            </div>
            <p>{{ audioTime }}</p>
          </div>
        </div>
        <audio ref="audio">
          <source src="@/assets/test-audio.mp3" type="audio/mp3">
        </audio>
      </div>
      <div class="script-text">
        <p>{{ scripture }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import infobox from '../components/InfoBox.vue'

export default {
  name: 'Home',
  data() {
    return {
      point1: false,
      point2: false,
      point3: false,
      point4: false,
      point5: false,
      point6: false,
      point7: false,
      date: '1785',
      treeName: 'Wright Jenkins',
      treeDate: '(1785)',
      treeImg: 'profile_tree4.svg',
      audioText: 'The Begining',
      treeText: 'We launched our genealogical study of the Stephen Jekins, Sr Family with Wright Jekins, Sr, a slave, born approximately 1785 in Hertford County, North Carolina. We know that he was the Father of Stephen Jenkins, Sr, however, we had to approximate his birth year.',
      audio: 'pause',
      audioTime: '0:00',
      currentTime: '',
      currentPercent: 0,
      audioEnd: '5:27',
      scripture: 'Romans 13:8',
      infoBoxEnabled: false,
      infoBoxMainText: '',
      infoBoxSubTitle: '',
      infoBoxTitle: ''
    }
  },
  components: {
    infobox
  },
  computed: {
    audioCurrentTime() {
      if (this.$refs.audio !== undefined) {
        const minutes = Math.floor(this.$refs.audio.duration / 60)
        const seconds = Math.floor(this.$refs.audio.duration % 60)
        const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`
        return `${minutes}:${returnedSeconds}`
      } else {
        return '0:00'
      }
    }
  },
  watch: {
    audioCurrentTime() {
      this.audioTime = this.audioCurrentTime
    }
  },
  methods: {
    setTree(name) {
      if (name === 'wright') {
        this.treeImg = 'profile_tree1.svg'
        this.treeName = 'Wright Jenkins'
        this.treeDate = '(1785)'
        this.treeText = 'We launched our genealogical study of the Stephen Jekins, Sr Family with Wright Jekins, Sr, a slave, born approximately 1785 in Hertford County, North Carolina. We know that he was the Father of Stephen Jenkins, Sr, however, we had to approximate his birth year.'
      }
      if (name === 'stephen') {
        this.treeImg = 'profile_tree2.svg'
        this.treeName = 'Stephen Jenkins, Sr.'
        this.treeDate = '(1810)'
        this.treeText = 'It is revealed in the early census that Stephen Jenkins, Sr., son of Wright Jenkins, Sr., was born into slavery in 1810 in Hertford County, North Carolina. An 1854 document lists him, and his family living on a farm in Maneys Neck, NC, near Murfreesboro.'
      }
      if (name === 'mary') {
        this.treeImg = 'profile_tree3.svg'
        this.treeName = 'Mary Jenkins Mitchell'
        this.treeDate = '(1838 - 1913)'
        this.treeText = "Mary Jenkins Mitchell, oldest child of Stephen Jenkins Sr., was born in Hertford County, NC. She was also the daughter of Louvenia Hayes Jenkins. An 1854 document lists her family on a slave farm in Maney's Neck, North Carolina, near Murfreesboro, NC. She was transferred to a slave farm in Bertie County, in a small section called 'Mitchellville', along with her sister Harriet. An early census document lists her living in the Mitchell Township at age 16, on the farm."
      }
      if (name === 'charles') {
        this.treeImg = 'profile_tree4.svg'
        this.treeName = 'Charles Jenkins'
        this.treeDate = '(1842 - 1915)'
        this.treeText = "The census records of Charles reveal that he was born January 19th, 1842 in Hertford County, NC. He was the second oldest child of Stephen Jenkins Sr. He was born a slave, and is documented as having lived on a slave farm in Maney's Neck, NC, near Murfreesboro, NC, in 1854. He was in bondage for the first 23 years."
      }
      if (name === 'harriet') {
        this.treeImg = 'profile_tree5.svg'
        this.treeName = 'Harriet Jenkins Mitchell'
        this.treeDate = '(1845 - 1924)'
        this.treeText = "Harriet is the thrid oldest child, born to Stephen and Louvenia Hayes Jenkins. She is also the granddaughter or Wright Jenkins Sr. She was born into salvery in Hertford County in 1845 on a slave farm in Maney's Neck, NC near Murfreesboro, NC. She was born 18 years before the passage of the Emancipation Proclamation. Although her father, Stephen Jenskins Sr was a slave, he stressed education to the best of his ability. He sent her to 'common school', as he did all of his children."
      }
      if (name === 'perry') {
        this.treeImg = 'profile_tree6.svg'
        this.treeName = 'Perry Jenkins'
        this.treeDate = '(1846 - 1903)'
        this.treeText = "Perry was in slavery and grea up the son of a farmer. An 1854 slave document lists him living on a slave farm in Maney's Neck, NC near Murfreesboro, NC, with other members of his family. At the age of 20 years, he married Louvenia Holloman in 1866 -- one year after the Emancipation Proclamation. He became a father of 11 children, 7 of which lived to become adults."
      }

      if (name === 'wright j') {
        this.treeImg = 'profile_tree7.svg'
        this.treeName = 'Wright J. Jenkins'
        this.treeDate = '(1850 - 1874)'
        this.treeText = "Wright J Jenkins, the fifth child of Stpehen Jenkins and Louvenia Hayes Jenkins, was born in Hertford COunty, into slavery, in 1850. He spent 15 years of his short life in bondage. He was named in honor of his grandfather, Wright Jenkins Sr. Five years after gaining his freedom, the MArriage Register of Hertford County, states that he married Sallie Holloman, sister to the wives of his two older brothers, Charles and Perry. He was the father of 2 children."
      }
      if (name === 'james') {
        this.treeImg = 'profile_tree8.svg'
        this.treeName = 'James Jenkins'
        this.treeDate = '(1851 - 1894)'
        this.treeText = "James Jenkins, the sixth child, and fourth son of Stephen Jenkins Sr and Louvenia Hayes Jenkins was born into slavery in 1851 in Hertford County, NC. Fourteen years of his young life was spent on a slave farm in Maney's Neck, NC. His father sent him to 'common school', even though he was a slave."
      }
      if (name === 'rachel') {
        this.treeImg = 'profile_tree9.svg'
        this.treeName = 'Rachel Jenkins Hollomon'
        this.treeDate = '(1859 - 1917)'
        this.treeText = "Rachel was the seventh child of Stephen and Louvenia Jenkins. She was born into slavery, according to a slave document dated 1854, on a slave farm in Maney's Neck, near Murfreesboro, NC in June, 1859. Two dates were given for her birth on the Census records, 1859 and 1860. We choose the first date that was given. She spent the first 6 years of her early life in slavery. Eventhough she was a slave, she attended 'common school'."
      }
      if (name === 'stephen jr') {
        this.treeImg = 'profile_tree10.svg'
        this.treeName = 'Stephen Jenkins, Jr.'
        this.treeDate = '(1860 - 1928)'
        this.treeText = "Stephen Jenkins Jr, the eighth oldest child of Stephen and Louvenia Jenkins, was born on a slave farm in Maney's Neck, Hertford County, NC in 1860. THe first five years of his life were spent in slave quaters. After 1865, he lived with his father, sister, bother, and his familyy in the homeplace on the Ahoskie-Winton Highway. Marriage records of Hertford County state the at the age of 26, on January 28, 1885, he married Jane Darden, who was 21 at the time."
      }
      if (name === 'lawrence') {
        this.treeImg = 'profile_tree11.svg'
        this.treeName = 'Lawrence Jenkins'
        this.treeDate = '(1861 - 1884)'
        this.treeText = "Lawrence Jenkins, the youngest child and youngest son of Stephen and Louvenia Jenkins, grandson of Wright Jenkins Sr, was born into slavery in Hertford County, four years before the Emancipation Proclimation. He never married. He died at an early age in Hertford County in 1884. The youngest son of his brother, James Jenkins, is named in his honor."
      }
    },
    showTimelineText(date) {
      this.date = date
      this.infoBoxEnabled = true
      this.infoBoxTitle = date

      if (date === '1785') {
        this.infoBoxSubTitle = 'The Begining'
        this.audioText = 'The Begining'
        this.infoBoxMainText = 'The genealogical study of the Stephen Jenkins Sr. family was launched with the documentation of Wright Jenkins, the family patriarch, a slave born approximately 1785 in Hertford County, North Carolina. In the 1700 and early 1800 censuses, slaves were not recorded in the census records as they were considered chattel property, represented only by numbers. Wright Jenkins Sr.’s only known child was Stephen Jenkins, Sr. His birth date and death date are recorded in the federal census and in the Hertford County, NC archives. Stephen Jenkins, Sr. is, therefore, the namesake of the family reunion and family association.'
      }

      if (date === '1810') {
        this.infoBoxSubTitle = 'First Born'
        this.audioText = 'First Born'
        this.infoBoxMainText = 'Wright Jenkins Sr.’s only known child was Stephen Jenkins, Sr. He was born in 1810 on a slave farm near Murfreesboro, NC. Stephen’s birth date and death date are recorded in the federal census and in the Hertford County, NC archives.  Stephen Jenkins, Sr. is, therefore, the namesake of the family reunion and family association.'
      }

      if (date === '1837') {
        this.infoBoxSubTitle = 'Marriage'
        this.audioText = 'Marriage'
        this.infoBoxMainText = 'In 1837 Stephen Jenkins, Sr. was “allowed” to marry Louvenia Hayes, who was also a slave. They were blessed with nine children, eight of which lived to become adults, married, and had families of their own. All of their children attended “common school” during slavery. After the civil war, Stephen Jenkins, Sr. had the means to purchase several tracts of land on the main highway which ran through the county. On this land, he built his family home, raised livestock, and farmed. His sons, Charles, Perry and Wright married and established family homes of their own. Two sons, James and Stephen, Jr. and their families lived at the home site along with a daughter, Rachael, and her family, who moved in after the death of her mother. Two daughters, Harriet and Mary, were slaves on a farm in Bertie County, NC prior to the Civil War. When the Civil War ended, Harriet moved with her family to a home near her father and siblings. Mary remained with her husband and family in Bertie County. The youngest child, Lawrence, never married and died at an early age.'
      }

      if (date === '1865') {
        this.infoBoxSubTitle = 'Land'
        this.audioText = 'Land'
        this.infoBoxMainText = "After 1865, Stephen Jenkins, Sr., purchased land on what is now, the Ahoskie-Winton Highway and built his family home, to which he moved from Maney's Neck."
      }

      if (date === '1869') {
        this.infoBoxSubTitle = 'Church'
        this.audioText = 'Church'
        this.infoBoxMainText = "Stephen Jenkins Sr.’s sons, “the Jenkins brothers,” were among a group of citizens who purchased land and constructed the first building for what is now known as the New Ahoskie Missionary Baptist Church. Several of the Jenkins siblings were among the first Deacons and Missionaries of the church, and son James, who became a minister, delivered the first sermon in the new church in 1869."
      }

      if (date === '1900') {
        this.infoBoxSubTitle = 'Fruits of Labor'
        this.audioText = 'Fruits of Labor'
        this.infoBoxMainText = "For a man who spent 55 years of his life in bondage, and whose children were born into bondage as well; Stephen Jenkins, Sr. managed a remarkable life. He was a landowner, farmer, father, and church leader who understood the value of education. His eighth child, Stephen, Jr. attended Shaw University in Raleigh, NC and studied under Henry Martin Tupper, founder of Shaw University. One of his grandsons attended medical school at Shaw University and another grandson left North Carolina in 1900 to attend law school."
      }

      if (date === '1988') {
        this.infoBoxSubTitle = 'Reunion'
        this.audioText = 'Reunion'
        this.infoBoxMainText = "The Stephen Jenkins, Sr. family heritage has been traced to the Fula People who inhabited land now known as Guinea Bissau, Africa. His descendants, along with their spouses, have distinguished themselves in virtually every field of human endeavor. In the Arts and Sciences; in education, law, business, religion, healthcare, athletics, military service, social activism, and philanthropy. Nearly 1,500 descendants of Stephen Jenkins, Sr. have been documented to date, spanning nine generations, residing in over 25 states and several foreign countries, over more than 230 years. Biennial reunions of the Stephen Jenkins, Sr. family have been held since 1988. The 16th reunion was held July 20-22, 2018 in the city of Durham, North Carolina. The theme for the 30th anniversary reunion was “Jenkins Family Legacy – 233 years, Now and Beyond.”"
      }
    },
    disableInfoBox() {
      this.infoBoxEnabled = false
      this.infoBoxMainText = ''
      this.infoBoxSubTitle = ''
      this.infoBoxTitle = ''
    },
    playAudio() {
      this.$refs.audio.play()
      this.audio = 'play'

      const barPercent = 100 / this.$refs.audio.duration

      let duration = this.$refs.audio.duration

      const setTime = () => {
        const minutes = Math.floor(duration / 60)
        const seconds = Math.floor(duration % 60)
        const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`
        this.audioTime = `-${minutes}:${returnedSeconds}`
      }

      if (this.currentTime === '') {
        setTime()
      } else {
        duration = this.currentTime
        setTime()
      }


      this.interval = setInterval(() => {
        if (duration < 1) {
          clearInterval(this.interval)
          this.audio = 'pause'
        } else {
          duration = duration - 1
          this.currentTime = duration
          this.currentPercent = this.currentPercent + barPercent
          this.$refs.playbar.style.width = this.currentPercent + '%'
          setTime()
          console.log(duration);
        }
      }, 1000);
    },
    pauseAudio() {
      this.$refs.audio.pause()
      this.audio = 'pause'
      clearInterval(this.interval)
    }
  },
}
</script>

<style scoped>
.container {
  width: 100vw;
  height: 92vh;
  background-image: url('../assets/tree.svg');
  background-size: 87% 87%;
  background-repeat: no-repeat;
  background-position: 50% 30%;
  box-sizing: border-box;
}

.landing-container {
  width: 100%;
  display: flex;
  align-items: center;
  height: 83%;
  box-sizing: border-box;
}

.landing-area {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
}

.landing-area:nth-child(2) {
  align-items: flex-end;
}

.timeline-container {
  padding-left: 3em;
  font-family: 'Inter', sans-serif;
}

.point-in-time {
  box-sizing: border-box;
  height: 50px;
}

.point-container {
  display: flex;
  align-items: center;
  cursor: pointer;
  box-sizing: border-box;
}

.point-container p {
  margin: 0;
}

.point-container span {
  opacity: .75;
  border-bottom: 1px dotted;
  font-family: 'Inter', sans-serif;
}

.point {
  width: 16px;
  height: 16px;
  border: 1px solid;
  border-radius: 100px;
  margin-right: .75em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.selected-point {
  width: 12px;
  height: 12px;
  border-radius: 100px;
  background-color: #38413f;
}

.time-line {
  width: 1px;
  height: 20px;
  background-color: #38413f;
  margin-left: .5em;
  margin-top: .25em;
  opacity: .25;
}

.tree-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 30%;
  margin-right: 3em;
  padding: 2em;
  border-radius: 5px;
  background-color: #fff;
  border: 1px solid;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.tree-card img {
  width: 150px;
  height: 150px;
  /* border-radius: 50%; 
  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px; */
}

.tree-card button {
  background-color: transparent;
  border: 1px solid;
  border-radius: 5px;
  padding: .5em 1em;
  cursor: pointer;
}

.tree-name {
  margin: 0;
  margin-top: 1em;
  margin-bottom: .25em;
  font-family: 'Inter', sans-serif;
  font-size: 1.2em;
}

.tree-date {
  margin: 0;
  font-family: 'Castoro', serif;
  font-size: 1.2em;
  font-style: italic;
  opacity: .7;
}

.tree-text {
  font-family: 'Inter', sans-serif;
  text-align: justify;
  font-size: .9em;
  line-height: 1.5;
  margin-bottom: 1em;
  opacity: .9;
}

.footer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 83%;
  z-index: 0;
  position: fixed;
  top: 85%;
}

.audio-container {
  margin-left: 4em;
}

.audio-text {
  font-family: 'Inter', sans-serif;
  font-size: .8em;
  opacity: .75;
  margin: 0;
}

.audio-box {
  margin-top: .5em;
  display: flex;
  align-items: center;
  padding: .5em 1em;
  background-color: #fff;
  border: 1px solid;
  border-radius: 5px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  width: 300px;
}

.controls {
  display: flex;
  align-items: center;
  width: 13%;
}

.controls img {
  width: 60%;
  cursor: pointer;
}

.playback {
  width: 87%;
  display: flex;
  align-items: center;
}

.playback p {
  margin: 0;
  font-family: 'Inter', sans-serif;
  font-size: .9em;
}

.playtime {
  width: 100%;
  height: 50%;
  height: 5px;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  display: flex;
  align-items: center;
  margin-right: 1em;
}

.playbar {
  width: 0%;
  height: 100%;
  background-color: #38413f;
  border-radius: 5px;
}

.audio-time {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.audio-time p {
  margin: 0;
  margin-top: .5em;
  font-family: 'Inter', sans-serif;
  font-size: .8em;
}

.script-text {
  height: 86.4px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-right: 4em;
}

.script-text p {
  margin: 0;
  font-family: 'Inter', sans-serif;
  opacity: .75;
  font-size: .8em;
  text-decoration: underline;
}

.pulses {
  z-index: 100;
}

.pulse {
  position: absolute;
  top: 80%;
  left: 49%;
  background: rgba(255, 255, 255, .8);
  border-radius: 50%;
  height: 30px;
  width: 30px;
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 1);
  transform: scale(1);
  animation: pulseA 2s infinite;
  cursor: pointer;
}

.pulse:nth-child(2) {
  top: 64%;
  left: 40%;
}

.pulse:nth-child(3) {
  top: 48%;
  left: 36%;
}

.pulse:nth-child(4) {
  top: 30%;
  left: 35%;
}

.pulse:nth-child(5) {
  top: 25%;
  left: 40%;
}

.pulse:nth-child(5) {
  top: 22%;
  left: 48%;
}

.pulse:nth-child(6) {
  top: 19%;
  left: 62%;
}

.pulse:nth-child(7) {
  top: 35%;
  left: 62%;
}

.pulse:nth-child(8) {
  top: 54%;
  left: 65%;
}

.pulse:nth-child(9) {
  top: 25%;
  left: 40%;
}

.pulse:nth-child(10) {
  top: 65%;
  left: 49%;
}

.pulse:nth-child(11) {
  top: 28%;
  left: 65%;
}

@keyframes pulseA {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 1);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0.2);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

.active-pulse {
  background: #fff;
}

.dates {
  z-index: 99;
}

.dates p {
  position: absolute;
  font-family: 'Inter', sans-serif;
  padding: .5em 1em;
  background-color: #fff;
  color: #38413f;
  border-radius: 5px;
  display: inline-block;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  font-size: .7em;
}

.dates p:nth-child(1) {
  top: 75%;
  left: 48%;
}

.dates p:nth-child(2) {
  top: 59%;
  left: 39%;
}

.dates p:nth-child(3) {
  top: 43%;
  left: 35%;
}

.dates p:nth-child(4) {
  top: 25%;
  left: 34%;
}

.dates p:nth-child(5) {
  top: 20%;
  left: 39%;
}

.dates p:nth-child(5) {
  top: 17%;
  left: 47%;
}

.dates p:nth-child(6) {
  top: 14%;
  left: 61%;
}

.dates p:nth-child(7) {
  top: 30%;
  left: 61%;
}

.dates p:nth-child(8) {
  top: 49%;
  left: 64%;
}

.dates p:nth-child(9) {
  top: 20%;
  left: 39%;
}

.dates p:nth-child(10) {
  top: 60%;
  left: 48%;
}

.dates p:nth-child(11) {
  top: 23%;
  left: 64%;
}

.tree-btns {
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  display: none;
}

.tree-btns p {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: .5em;
  border: 1px solid #38413f !important;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  border-radius: 5px;
  margin: 0;
  margin-bottom: .5em;
  margin-right: .5em;
  font-family: 'Inter', sans-serif;
  font-size: .8em;
}

@media screen and (max-width: 1800px) {
  .pulse:nth-child(5) {
    top: 32%;
    left: 48%;
  }

  .pulse:nth-child(6) {
    top: 33%;
    left: 55%;
  }

  .pulse:nth-child(9) {
    top: 35%;
    left: 40%;
  }

  .dates p:nth-child(5) {
    top: 26%;
    left: 47%;
  }

  .dates p:nth-child(6) {
    top: 27%;
    left: 54%;
  }

  .dates p:nth-child(9) {
    top: 29%;
    left: 39%;
  }
}

@media screen and (max-width: 1650px) {
  .pulse {
    top: 73%;
    left: 49%;
  }

  .pulse:nth-child(2) {
    top: 58%;
    left: 40%;
  }

  .dates p:nth-child(1) {
    top: 69%;
    left: 48%;
  }

  .dates p:nth-child(2) {
    top: 54%;
    left: 39%;
  }
}

@media screen and (max-width: 1500px) {
  .timeline-container {
    padding-left: 3em;
  }

  .tree-card {
    margin-right: 3em;
  }

  .tree-card {
    padding: 1em;
  }
}

@media screen and (max-width: 1380px) {
  .tree-card {
    margin-right: 2em;
  }

  .timeline-container {
    padding-left: 2em;
  }
}

@media screen and (max-width: 1250px) {

  .pulse:nth-child(4) {
    top: 33%;
    left: 35%;
  }

  .pulse:nth-child(11) {
    top: 32%;
    left: 67%;
  }

  .dates p:nth-child(11) {
    top: 27%;
    left: 67%;
  }

  .dates p:nth-child(4) {
    top: 28%;
    left: 34%;
  }
}

@media screen and (max-width: 1150px) {
  .container {
    background-image: none;
  }

  .pulses {
    display: none;
  }

  .dates {
    display: none;
  }

  .landing-container {
    flex-direction: column;
    height: auto;
    overflow-y: hidden;
  }

  .landing-area {
    width: 100%;
    height: auto;
    justify-content: center;
    align-items: center !important;
    overflow-x: scroll;
    padding: 0 1em;
  }

  .landing-area::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  .landing-area:first-child {
    height: 40px;
  }

  .landing-area:last-child {
    padding: 1em;
  }

  .time-line {
    display: none;
  }

  .point-in-time {
    height: auto;
    margin-right: .5em;
  }

  .timeline-container {
    width: 100%;
    padding: 0;
    display: flex;
    align-items: center;
    box-sizing: border-box;
  }

  .point {
    display: none;
  }

  .point-text {
    padding: .5em;
    border: 1px solid #38413f !important;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    border-radius: 25px;
    width: 10em;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .point-container p {
    font-size: .9em;
    font-family: 'Inter', sans-serif;
  }

  .selected {
    background-color: #3a86ff !important;
    color: #fff;
  }

  .point-container span {
    opacity: 1;
    border-bottom: 0;
    margin-left: .25em;
  }

  .tree-btns {
    display: flex;
  }

  .tree-card {
    width: 90%;
    margin: 0;
    border-radius: 10px;
    background-color: transparent;
    border: 0;
    box-shadow: none;
    padding: .5em;
  }

  .tree-card img {
    display: none;
  }

  .tree-card button {
    display: none;
  }

  .tree-text {}

  .tree-name {
    margin-top: 0;
  }

  .script-text {
    display: none;
  }

  .footer {
    margin-top: 1em;
    justify-content: center;
  }

  .audio-container {
    margin: 0;
  }
}
</style>
