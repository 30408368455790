<script setup>
import { ref, watchEffect, watch, onMounted } from "vue";
import { useRouter } from "vue-router";
import { post, generateClient } from "aws-amplify/api";
import { createReunionSignUp } from "@/graphql/mutations";
import { uploadData } from "aws-amplify/storage";

const client = generateClient();

const router = useRouter();

const stripe = window.Stripe(
  "pk_live_51OkGFjKJtseLuisu6Pajka26S39qu2RY2JZseU7o6AUfB3ALxlIMqaFbLwIOrwz09DrQwXKbQcxmQncpVooJGzJd00lOUickMQ"
);

let elements;

const showPay = ref(false);
const receiptEmail = ref("");

async function initialize() {
  try {
    const operation = post({
      apiName: "stripe",
      path: "/payment",
      options: {
        body: {
          amount: getTotal() + "00",
          description: getDescription(),
          email: receiptEmail.value,
        },
      },
    });

    const { body } = await operation.response;
    const { clientSecret } = await body.json();

    const appearance = {
      theme: "stripe",
    };

    elements = stripe.elements({ appearance, clientSecret });

    const options = {
      layout: {
        type: "accordion",
        defaultCollapsed: false,
        radios: true,
        spacedAccordionItems: false,
      },
    };

    const paymentElement = elements.create("payment", options);
    paymentElement.mount("#payment-element");

    showPay.value = true;
  } catch (error) {
    alert(error);
  }
}

function setEmail() {
  if (primaryInfo.value.length === 0) {
    if (patronEmail.value !== "") {
      return patronEmail.value;
    }
    if (adEmail.value !== "") {
      return adEmail.value;
    }
  } else {
    return primaryInfo.value[1];
  }
}

async function pay() {
  try {
    const { paymentIntent } = await stripe.confirmPayment({
      elements,
      redirect: "if_required",
    });

    if (paymentIntent.status === "succeeded") {
      alert(`Payment was successful!, an email has been sent to ${receiptEmail.value}`);

      let textArray = [];

      const groupText = groupMembers.value.forEach((item) => {
        let eachText = `${item.name} [${item.age}], BanquetOnly: ${item.banquetOnly} | `;
        textArray.push(eachText);
      });

      const newReunionSignUp = await client.graphql({
        query: createReunionSignUp,
        variables: {
          input: {
            PrimaryFullName: primaryInfo.value[0],
            PrimaryEmail: primaryInfo.value[1],
            PrimryAddress: primaryInfo.value[2],
            PrimaryPhone: primaryInfo.value[3],
            PrimaryAge: primaryInfo.value[4],
            GroupMembers: textArray,
            FamilyBranch: selectedBranch.value,
            PatronLevel: patronSelect.value,
            PatronName: patronName.value,
            PatronEmail: patronEmail.value,
            PatronDescription: patronDescription.value,
            AdType: selectedAd.value,
            AdName: adName.value,
            AdEmail: adEmail.value,
            AdDescription: adDescription.value,
          },
        },
      });

      if (adFile.value !== null) {
        sendFile();
      }

      checkoutPage.value = false;
      router.push("/family-reunion");
    } else {
      alert("Payment failed, please check payment details and try again!");
    }
  } catch (error) {
    alert(error);
  }
}

const checkoutPage = ref(false);

const primaryInfo = ref([]);
const groupMembers = ref([{ name: "", age: "", banquetOnly: false }]);

const primaryInputs = [
  {
    title: "Primary Full Name",
    type: "text",
  },
  {
    title: "Primary Email Address",
    type: "email",
  },
  {
    title: "Primary Mailing Address (Please include City, State, and Zipcode)",
    type: "text",
  },
  {
    title: "Primary Phone Number",
    type: "number",
  },
  {
    title: "Primary Age",
    type: "number",
  },
];
const patronLevels = ref([
  { title: "Platinum", price: 300 },
  { title: "Gold", price: 150 },
  { title: "Silver", price: 75 },
  { title: "Bronze", price: 25 },
  { title: "Patron", price: 10 },
]);
const patronSelect = ref(null);
const patronAmount = ref();
const patronName = ref("");
const patronEmail = ref("");
const patronDescription = ref("");

const reunionInfo = [
  {
    title: "Registration",
    info: "",
  },
  {
    title: "Registration Deadline",
    info: "No registrations accepted after July 10th. Late pricing applied to registrations and payments submitted after June 30th.",
  },
  {
    title: "Hotel & Discount Deadline",
    info: "hotel",
  },
];

const ads = ref([
  { size: "Full Page", price: 100 },
  { size: "Half Page", price: 60 },
  { size: "Quarter Page", price: 25 },
]);
const selectedAd = ref(null);
const adPrice = ref();
const adName = ref("");
const adEmail = ref("");
const adDescription = ref("");
const adFile = ref(null);

const branches = ["Mary", "Charles", "Harriet", "Perry", "James", "Rachel", "Stephen Jr.", "Wright"];

const members = ref(0);
const selectedBranch = ref("");

const tickets = ref([]);

const shirts = ref([
  { size: "YXS", shirts: 0, type: "Youth" },
  { size: "YS", shirts: 0, type: "Youth" },
  { size: "YM", shirts: 0, type: "Youth" },
  { size: "YL", shirts: 0, type: "Youth" },
  { size: "YXL", shirts: 0, type: "Youth" },
  { size: "XS", shirts: 0, type: "Adult" },
  { size: "S", shirts: 0, type: "Adult" },
  { size: "M", shirts: 0, type: "Adult" },
  { size: "L", shirts: 0, type: "Adult" },
  { size: "XL", shirts: 0, type: "Adult" },
  { size: "2XL", shirts: 0, type: "Adult" },
  { size: "3XL", shirts: 0, type: "Adult" },
  { size: "4XL", shirts: 0, type: "Adult" },
]);

function addMember() {
  members.value <= 10 ? members.value++ : null;

  groupMembers.value.push({ name: "", age: "", banquetOnly: false });
}

function setTickets() {
  let a1 = 0;
  let a2 = 0;
  let a3 = 0;
  let a4 = 0;
  let a5 = 0;

  const t = [];

  groupMembers.value.forEach((item) => {
    if (item.age < 7) {
      t.push({
        age: item.age,
        price: 0,
        banquet: item.banquetOnly ? "(Banquet Only)" : "",
      });
    }
    if (item.age < 13 && item.age > 6) {
      t.push({
        age: item.age,
        price: item.banquetOnly ? 65 : 60,
        banquet: item.banquetOnly ? "(Banquet Only)" : "",
      });
    }
    if (item.age < 19 && item.age > 12) {
      t.push({
        age: item.age,
        price: item.banquetOnly ? 65 : 70,
        banquet: item.banquetOnly ? "(Banquet Only)" : "",
      });
    }
    if (item.age < 75 && item.age > 18) {
      t.push({
        age: item.age,
        price: item.banquetOnly ? 85 : 145,
        banquet: item.banquetOnly ? "(Banquet Only)" : "",
      });
    }
    if (item.age >= 75) {
      t.push({
        age: item.age,
        price: item.banquetOnly ? 85 : 135,
        banquet: item.banquetOnly ? "(Banquet Only)" : "",
      });
    }

    const total = [
      { age: "0-6", tickets: a1, price: 0, banquet: item.banquetOnly ? "(Banquet Only)" : "" },
      {
        age: "7-12",
        tickets: a2,
        price: item.banquetOnly ? 65 : 60,
        banquet: item.banquetOnly ? "(Banquet Only)" : "",
      },
      {
        age: "13-18",
        tickets: a3,
        price: item.banquetOnly ? 65 : 70,
        banquet: item.banquetOnly ? "(Banquet Only)" : "",
      },
      {
        age: "19-74",
        tickets: a4,
        price: item.banquetOnly ? 85 : 145,
        banquet: item.banquetOnly ? "(Banquet Only)" : "",
      },
      {
        age: "75+",
        tickets: a5,
        price: item.banquetOnly ? 85 : 135,
        banquet: item.banquetOnly ? "(Banquet Only)" : "",
      },
    ];
  });

  tickets.value = t;
}

function setPatron(level, price) {
  if (patronSelect.value === level) {
    patronSelect.value = null;
    patronAmount.value = null;
  } else {
    patronSelect.value = level;
    patronAmount.value = price;
  }
}

function setAds(type, price) {
  if (selectedAd.value === type) {
    selectedAd.value = null;
    adPrice.value = null;
  } else {
    selectedAd.value = type;
    adPrice.value = price;
  }
}

function getTotal() {
  let ticketTotal = 0;
  let patron = 0;
  let ads = 0;
  let shirtPrice = 0;

  if (tickets.value.length > 0) {
    tickets.value.forEach((item) => {
      ticketTotal = ticketTotal + item.price;
    });
  }

  if (patronAmount.value > 0) {
    patron = patronAmount.value;
  }

  if (adPrice.value > 0) {
    ads = adPrice.value;
  }

  shirts.value.forEach((item) => {
    if (item.shirts > 0) {
      if (item.type === "Youth") {
        shirtPrice = shirtPrice + item.shirts * 25;
      } else {
        shirtPrice = shirtPrice + item.shirts * 30;
      }
    }
  });

  let total = ticketTotal + patron + ads + shirtPrice;

  return total;
}

function getDescription() {
  let ticketsText = "";
  let patronsText = "";
  let adsText = "";
  let shirtsText = "";

  tickets.value.forEach((s) => {
    ticketsText = ticketsText + `1 Ticket — Age: ${s.age} ${s.banquet} | `;
  });

  if (patronSelect.value !== null) {
    patronsText = `Patron — ${patronSelect.value} | `;
  }

  if (selectedAd.value !== null) {
    adsText = `Ad — ${selectedAd.value} | `;
  }

  for (let s of shirts.value) {
    if (s.shirts > 0) {
      shirtsText = shirtsText + `${s.type} T-Shirt — ${s.size} (${s.shirts}) | `;
    }
  }

  return ticketsText + patronsText + adsText + shirtsText;
}

function checkout() {
  let missingPrimary = false;

  if (getTotal() > 0 && receiptEmail.value !== "") {
    if (primaryInfo.value.length > 0) {
      [0, 1, 2, 3, 4].forEach((item) => {
        if (primaryInfo.value[item] === undefined) {
          missingPrimary = true;
        }
      });

      if (missingPrimary) {
        alert("Please fill in all fields in the Primary Registration Holder Information section.");
      } else {
        checkoutPage.value = true;
        initialize();
      }
    }
    if (patronSelect.value !== null) {
      if (patronEmail.value === "") {
        alert("Please add a Patron Email Address.");
      } else {
        checkoutPage.value = true;
        initialize();
      }
    }
    if (selectedAd.value !== null) {
      if (adEmail.value === "") {
        alert("Please add a Booklet Ad Email Address.");
      } else {
        checkoutPage.value = true;
        initialize();
      }
    }
    if (primaryInfo.value.length === 0 && patronSelect.value === null && selectedAd.value === null) {
      checkoutPage.value = true;
      initialize();
    }
  } else {
    alert("Please add an item and enter a Receipt Email to Checkout.");
  }
}

async function upload(data) {
  adFile.value = data.target.files[0];
}

async function sendFile() {
  try {
    const result = await uploadData({
      key: `${adEmail.value}-adfile`,
      data: adFile.value,
    }).result;
    console.log("Succeeded: ", result);
  } catch (error) {
    console.log("Error : ", error);
  }
}

watchEffect(() => {
  groupMembers.value[0].name = primaryInfo.value[0];
  groupMembers.value[0].age = primaryInfo.value[4];
});

watch(groupMembers.value, (newValue, oldValue) => {
  if (newValue) {
    setTickets();
  }
});

onMounted(() => {});
</script>

<template>
  <main>
    <header>
      <div class="logo-box">
        <img src="@/assets/sjslogo_update.svg" alt="" />
      </div>
    </header>
    <form v-if="!checkoutPage">
      <h2 class="heading">2024 Family Reunion Registration Form</h2>
      <p class="sub-heading">Registration for 2024 Stephen Jenkins Family Reunion in Raleigh NC is Now Open.</p>
      <table>
        <caption>
          Reunion Registration Pricing
        </caption>
        <tr>
          <th>Age</th>
          <th>0-6</th>
          <th>7-12</th>
          <th>13-18</th>
          <th>19-74</th>
          <th>75+</th>
        </tr>
        <tr>
          <th>General</th>
          <td>Free</td>
          <td>$60</td>
          <td>$70</td>
          <td>$145</td>
          <td>$135</td>
        </tr>
        <tr>
          <th>Late</th>
          <td>Free</td>
          <td>$65</td>
          <td>$75</td>
          <td>$150</td>
          <td>$140</td>
        </tr>
      </table>
      <p class="only">*Banquet Only: $85 adult, $65 child</p>
      <div class="info-box" v-for="r in reunionInfo">
        <h5>{{ r.title }}</h5>
        <p class="details" v-if="r.title === 'Registration'">
          Please read instructions carefully and input information on the form below to register for the reunion.
        </p>
        <p class="details" v-if="r.title === 'Registration'">
          If you are not attending the reunion but you wish to become a patron or purchase a commemorative Program
          Booklet Ad, please proceed to those sections of the form.
        </p>
        <p class="details" v-if="r.title === 'Registration'">
          * Input of registrants' age is required in order for the registration to process.
        </p>
        <p class="details" v-if="r.title === 'Registration'">
          *********** PLEASE CHECK/REVIEW YOUR ORDER BEFORE SUBMITTING THE FORM ***********
        </p>
        <p class="details" v-if="r.title === 'Registration'">
          IF YOU NEED ASSISTANCE WITH THIS REGISTRATION PROCESS, PLEASE CONTACT YOUR FAMILY REPRESENTATIVE OR EMAIL
          STEPHENJENKINSFAMILY@GMAIL.COM AND SOMEONE WILL REACH OUT TO YOU WITHIN 2 BUSINESS DAYS.
        </p>
        <p v-if="r.info !== 'hotel'">
          {{ r.info }}
        </p>
        <div class="hotel-info" v-if="r.info === 'hotel'">
          <p class="sub-heading">Special hotel discount rate expires June 27th.</p>
          <p class="hotel-address">
            <strong>Embassy Suites by Hilton Raleigh Durham Research Triangle</strong><br />
            201 Harrison Oaks Boulevard, Cary, NC 27513 <br />
            <span>+1 (919)-677-1840</span><br />
          </p>
          <p class="hotel-address">
            <strong>Room & Rates</strong> <br />
            Room Suite-1 King Bed or 2 Double Beds - nonsmoking <br />
            Single Occupancy - $169.00 <br />
            Double Occupancy - $169.00 <br />
            Triple Occupancy - $179.00 <br />
            Quadruple Occupancy - $189.00 <br />
          </p>
          <a class="click-here slashed"> Click here to book a room </a>
          <h2 class="soldout">Embassy Suites Hotel is now SOLD OUT</h2>
          <p class="pad">Please use the button below to purchase a room at our overflow hotel.</p>
          <a
            class="click-here"
            href="https://www.marriott.com/event-reservations/reservation-link.mi?id=1717418472186&key=GRP&app=resvlink"
          >
            Click here to book a overflow room
          </a>
        </div>
      </div>
      <div class="separator"></div>
      <section>
        <h6 class="section-title">Primary Registration Holder Information</h6>
        <p class="section-text">
          Enter the information for the primary registration holder. The primary registration holder can register a
          group of up to 10 people.
        </p>
        <div class="form-section" v-for="(p, index) in primaryInputs">
          <div class="title">
            <p>{{ p.title }}</p>
          </div>
          <div class="data">
            <input class="text" :placeholder="p.title" :type="p.type" v-model="primaryInfo[index]" />
          </div>
        </div>
        <div class="separator"></div>
      </section>
      <section>
        <h6 class="section-title">Group Details</h6>
        <p class="section-text">
          If you are registering for a group (2 or more people), enter each member's name and age below.
        </p>
        <div class="add-new">
          <p class="green" @click="addMember()">+ Add</p>
          <p class="red" @click="members > 0 ? members-- : null">- Remove</p>
        </div>
        <div class="form-section">
          <div class="input-row primary">
            <div class="row-item">
              <label>Name</label>
              <input
                class="text primary-member"
                type="text"
                placeholder="Primary Name"
                v-model="primaryInfo[0]"
                readonly
              />
            </div>
            <div class="row-item">
              <label>Age</label>
              <input
                class="text primary-member"
                type="number"
                placeholder="Primary Age"
                v-model="primaryInfo[4]"
                readonly
              />
            </div>
            <div class="banquet end">
              <input type="checkbox" v-model="groupMembers[0].banquetOnly" />
              <label for="">Banquet Only</label>
            </div>
          </div>
          <div class="input-row" v-for="(num, index) in members">
            <div class="row-item">
              <label>Name {{ index + 1 }}</label>
              <input class="text" type="text" placeholder="Name" v-model="groupMembers[index + 1].name" />
            </div>
            <div class="row-item">
              <label>Age {{ index + 1 }}</label>
              <input class="text" type="number" placeholder="Age" v-model="groupMembers[index + 1].age" />
            </div>
            <div class="banquet end">
              <input type="checkbox" v-model="groupMembers[index + 1].banquetOnly" />
              <label for="">Banquet Only</label>
            </div>
          </div>
        </div>
        <div class="separator"></div>
      </section>
      <section>
        <h6 class="section-title">Family Branch</h6>
        <p class="section-text">Select your group's family branch.</p>
        <div class="form-section">
          <div class="branches">
            <p
              class="branch"
              v-for="b in branches"
              @click="selectedBranch === b ? (selectedBranch = '') : (selectedBranch = b)"
              :class="selectedBranch === b ? 'selected-branch' : null"
            >
              {{ b }}
            </p>
          </div>
        </div>
        <div class="separator"></div>
      </section>
      <section>
        <h6 class="section-title">Become a Patron</h6>
        <p class="section-text">
          Become a Platinum, Gold, Silver, Bronze or Patron supporter. Your name, that of an honoree, or the name of
          your company will appear in the 2024 Commemorative Jenkins Reunion program booklet. Here are the levels of
          support:
        </p>
        <div class="form-section">
          <div class="patron-amount">
            <div
              class="amount"
              v-for="p in patronLevels"
              @click="setPatron(p.title, p.price)"
              :class="patronSelect === p.title ? 'selected-amount' : null"
            >
              <p>{{ p.title }}</p>
              <span>${{ p.price }}</span>
            </div>
          </div>
          <div class="input-row">
            <div class="row-item row-item-long">
              <label>Patron Name</label>
              <input class="text" type="text" placeholder="Patron Name" v-model="patronName" />
            </div>
          </div>
          <div class="input-row">
            <div class="row-item row-item-long">
              <label>Patron Email</label>
              <input class="text" type="text" placeholder="Patron Name" v-model="patronEmail" />
            </div>
          </div>
          <div class="input-row">
            <div class="row-item row-item-long">
              <label>Please tell us how your contribution should be listed. </label>
              <textarea class="text" type="text" placeholder="Your answer..." v-model="patronDescription"></textarea>
            </div>
          </div>
        </div>
        <div class="separator"></div>
      </section>
      <section>
        <h6 class="section-title">Commemorative Program Booklet Ads</h6>
        <p class="section-text">
          PLEASE UPLOAD "COPY READY" AD DOCUMENT. If assistance is needed, you may contact Cynthia Banks at 267-908-1595
        </p>
        <div class="form-section">
          <div class="patron-amount">
            <div
              class="amount amount-wide"
              v-for="(a, index) in ads"
              @click="setAds(a.size, a.price)"
              :class="selectedAd === a.size ? 'selected-amount' : null"
            >
              <p>{{ a.size }}</p>
              <span>${{ a.price }}</span>
            </div>
          </div>
          <div class="input-row">
            <div class="row-item row-item-long">
              <label>Contact Name</label>
              <input class="text" type="text" placeholder="Name" v-model="adName" />
            </div>
          </div>
          <div class="input-row">
            <div class="row-item row-item-long">
              <label>Contact Email</label>
              <input class="text" type="text" placeholder="Patron Name" v-model="adEmail" />
            </div>
          </div>
          <div class="row-item row-item-long">
            <label>Please provide any info regarding the ad (Title, Description, Image placement).</label>
            <textarea class="text" type="text" placeholder="Your answer..." v-model="adDescription"></textarea>
          </div>
        </div>
        <div class="upload">
          <h4>Upload Your Booklet Ad Files</h4>
          <label>
            <input type="file" @change="upload($event)" />
          </label>
        </div>
        <div class="separator"></div>
      </section>
      <section>
        <h6 class="section-title">Shop Add-Ons</h6>
        <p>
          Souvenir T-Shirts can only be picked up on site at the reunion at this time. We apologize for any
          inconvenience.
        </p>
        <div class="form-section">
          <div class="shop-box">
            <div class="shop-item">
              <div class="img-box">
                <img src="@/assets/shirt.jpg" alt="" />
              </div>
              <div class="item-info">
                <div class="item-text">
                  <p>2024 Family reunion T-Shirt</p>
                  <span>Youth $25 / Regular $30</span>
                </div>
                <div class="sizes">
                  <div class="size-box">
                    <div class="size-type">Youth</div>
                    <div class="size">
                      <span>YXS</span>
                      <input type="number" placeholder="0" v-model="shirts[0].shirts" />
                    </div>
                    <div class="size">
                      <span>YS</span>
                      <input type="number" placeholder="0" v-model="shirts[1].shirts" />
                    </div>
                    <div class="size">
                      <span>YM</span>
                      <input type="number" placeholder="0" v-model="shirts[2].shirts" />
                    </div>
                    <div class="size">
                      <span>YL</span>
                      <input type="number" placeholder="0" v-model="shirts[3].shirts" />
                    </div>
                    <div class="size">
                      <span>YXL</span>
                      <input type="number" placeholder="0" v-model="shirts[4].shirts" />
                    </div>
                  </div>
                </div>
                <div class="sizes">
                  <div class="size-box">
                    <div class="size-type">Adult</div>
                    <div class="size">
                      <span>XS</span>
                      <input type="number" placeholder="0" v-model="shirts[5].shirts" />
                    </div>
                    <div class="size">
                      <span>S</span>
                      <input type="number" placeholder="0" v-model="shirts[6].shirts" />
                    </div>
                    <div class="size">
                      <span>M</span>
                      <input type="number" placeholder="0" v-model="shirts[7].shirts" />
                    </div>
                    <div class="size">
                      <span>L</span>
                      <input type="number" placeholder="0" v-model="shirts[8].shirts" />
                    </div>
                    <div class="size">
                      <span>XL</span>
                      <input type="number" placeholder="0" v-model="shirts[9].shirts" />
                    </div>
                    <div class="size">
                      <span>2XL</span>
                      <input type="number" placeholder="0" v-model="shirts[10].shirts" />
                    </div>
                    <div class="size">
                      <span>3XL</span>
                      <input type="number" placeholder="0" v-model="shirts[11].shirts" />
                    </div>
                    <div class="size">
                      <span>4XL</span>
                      <input type="number" placeholder="0" v-model="shirts[12].shirts" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="separator"></div>
      </section>
      <section>
        <h6></h6>
      </section>
      <section>
        <h6 class="section-title">Sub Total</h6>
        <div class="cart-row" v-for="s in tickets">
          <p class="">{{ `1 Ticket — Age: ${s.age} ${s.banquet}` }}</p>
          <p class="price">${{ s.price }}</p>
        </div>
        <div class="cart-row" v-if="patronSelect !== null">
          <p class="">{{ `Patron — ${patronSelect}` }}</p>
          <p class="price">${{ patronAmount }}</p>
        </div>
        <div class="cart-row" v-if="selectedAd !== null">
          <p class="">{{ `Booklet Ad — ${selectedAd}` }}</p>
          <p class="price">${{ adPrice }}</p>
        </div>
        <div class="cart-row" v-for="s in shirts">
          <p class="" v-if="s.shirts > 0">{{ `${s.type} T-Shirt — ${s.size} (${s.shirts})` }}</p>
          <p class="price" v-if="s.shirts > 0">${{ s.type === "Youth" ? s.shirts * 25 : s.shirts * 30 }}</p>
        </div>
        <div class="total">
          <h3>${{ getTotal() }}</h3>
        </div>
      </section>
      <div class="form-section no-margin">
        <div class="input-row">
          <div class="row-item row-item-long">
            <label>Receipt Email</label>
            <input class="text" type="text" placeholder="Receipt Email Address" v-model="receiptEmail" />
          </div>
        </div>
      </div>
      <button type="button" class="submit" @click="checkout()">Checkout</button>
    </form>
    <div class="checkout" v-if="checkoutPage">
      <section>
        <h6 class="section-title">Total</h6>
        <div class="cart-row" v-for="s in tickets">
          <p class="">{{ `1 Ticket — Age: ${s.age} ${s.banquet}` }}</p>
          <p class="price">${{ s.price }}</p>
        </div>
        <div class="cart-row" v-if="patronSelect !== null">
          <p class="">{{ `Patron — ${patronSelect}` }}</p>
          <p class="price">${{ patronAmount }}</p>
        </div>
        <div class="cart-row" v-if="selectedAd !== null">
          <p class="">{{ `Booklet Ad — ${selectedAd}` }}</p>
          <p class="price">${{ adPrice }}</p>
        </div>
        <div class="cart-row" v-for="s in shirts">
          <p class="" v-if="s.shirts > 0">{{ `${s.type} T-Shirt — ${s.size} (${s.shirts})` }}</p>
          <p class="price" v-if="s.shirts > 0">${{ s.type === "Youth" ? s.shirts * 25 : s.shirts * 30 }}</p>
        </div>
        <div class="total">
          <h3>Due Now:</h3>
          <h3>${{ getTotal() }}</h3>
        </div>
      </section>
      <div id="payment-element"></div>
      <button type="button" class="submit pay" @click="pay()" v-if="showPay">Pay Now</button>
    </div>
  </main>
</template>

<style scoped>
main {
  display: flex;
  place-items: center;
  flex-direction: column;
  font-family: "Inter", sans-serif;
  width: 100dvw;
  padding: 0 0.5rem;
  box-sizing: border-box;
}

header {
  width: 80%;
}

.logo-box {
  width: 100%;
  display: flex;
  place-content: center;
}

header img {
  width: 300px;
  height: 300px;
}

section {
  width: 100%;
}

input:focus {
  outline: none;
}

.info-box {
  margin: 2rem 0;
}

.info-box h5 {
  font-weight: normal;
  margin: 0;
  font-size: 1.25rem;
  line-height: 2;
}

.info-box p {
  margin: 0;
  line-height: 1.5;
}

.details {
  margin: 1rem 0 !important;
}

.hotel-address {
  margin-bottom: 2rem !important;
}

.hotel-address:nth-child(2) {
  margin-bottom: 2rem !important;
}

.hotel-address span {
  font-size: 0.9rem;
  opacity: 0.8;
  border-bottom: 1px solid;
}

.hotel-info .sub-heading {
  margin-bottom: 1rem;
}

.hotel-address strong {
  font-weight: 600;
}

.separator {
  width: 100%;
  height: 2px;
  background: #f2f2f2;
}

.section-title {
  font-weight: normal;
  font-size: 1.25rem;
  margin-bottom: 0;
  line-height: 2;
}

.section-text {
  margin: 0;
  color: #999;
}

form {
  margin-top: 2rem;
  background-color: #fff;
  box-shadow: rgb(0 0 0 / 16%) 0px 3px 6px, rgb(0 0 0 / 23%) 0px 3px 6px;
  border-radius: 5px;
  padding: 2rem 1rem;
  font-family: "Inter", sans-serif;
  color: #38413f;
  margin-bottom: 3em;
  font-weight: bold;
  box-sizing: border-box;
  width: 100%;
  font-size: 15px;
}

.banquet {
  border: 1px solid;
  font-size: 0.9em;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  width: 120px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 1rem;
  margin-bottom: 1rem;
  padding: 0 0.5rem;
}

.end {
  margin-top: 2.9rem !important;
}

table {
  margin-top: 2rem;
  border: 1px solid;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  font-size: 0.8rem;
}

caption {
  text-align: left;
  margin-bottom: 1rem;
}

th,
td {
  font-weight: normal;
  padding: 0.4rem;
}

.only {
  font-size: 0.8rem;
}

th {
}

td {
}

.heading {
  font-weight: normal;
  font-size: 1.5rem;
  margin-bottom: 0.25rem;
}

.sub-heading {
  color: #999;
  margin: 0;
}

.form-section {
  width: 100%;
  margin: 2rem 0;
}

.form-section .text {
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  border-radius: 5px;
  padding: 0.5em;
  border: 1px solid;
  font-size: 1.2em;
  width: 100%;
  box-sizing: border-box;
}

.primary-options {
  display: flex;
  align-items: center;
}

.input-row {
  width: 100%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  justify-content: space-between;
}

.row-item {
  width: 40%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.row-item-long {
  margin-bottom: 1rem;
  width: 100% !important;
}

.row-item label {
  font-size: 0.9rem;
  line-height: 2;
}

.row-item input {
  font-size: 1rem;
  padding: 0.5rem;
}

.add-new {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 1rem 0;
}

.add-new p {
  padding: 0.5rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #f1f1f1;
  margin-right: 1rem;
  font-size: 1rem;
}

.green {
  background-color: #52b788 !important;
  color: #fff;
  border: 1px solid #38413f !important;
}

.red {
  background-color: #f25c54 !important;
  color: #fff;
  border: 1px solid #38413f !important;
}

.primary-member {
  border: none !important;
  background-color: rgba(0, 0, 0, 0.12) !important;
  box-shadow: none !important;
}

.branches {
  display: flex;
  flex-wrap: wrap;
}

.branch {
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  border-radius: 5px;
  padding: 0.5em;
  border: 1px solid;
  margin-bottom: 0.5rem;
  margin-right: 1rem;
  cursor: pointer;
}

.selected-branch {
  background: #38413f;
  color: #fff;
  border: none;
}

.upload {
  padding: 1rem 0;
}

.shop-box {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  place-content: center;
}

.shop-item {
  width: 50%;
  display: flex;
  place-items: center;
  flex-direction: column;
}

.img-box {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  place-content: center;
}

.img-box img {
  width: 70%;
  object-fit: cover;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  border: 1px solid;
}

.item-info {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  text-align: left;
}

.item-info p {
  margin: 0;
  line-height: 1.5;
}

.item-info span {
  color: #999;
}

.item-text {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 1rem;
}

.sizes {
  display: flex;
  place-content: center;
}

.size-box {
  border: 1px solid;
  border-radius: 5px;
  width: 300px;
  margin: 2rem 0;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}

.size-type {
  border-bottom: 1px solid #e6e6e6;
  padding: 1rem;
  font-size: 1rem;
}

.size {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}

.size input {
  font-size: 1rem;
  border: 1px solid #e6e6e6;
  width: 40px;
  border-radius: 3px;
}

.size span {
  font-size: 1rem;
  color: #000;
}

.description {
  color: #999;
  max-width: 100% !important;
  line-height: 1.25;
}

.item-info button {
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  border-radius: 5px;
  padding: 1rem 0.5em;
  border: 1px solid;
  margin-bottom: 0.5rem;
  margin-right: 1rem;
  cursor: pointer;
}

.cart-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cart-row p {
  margin: 0;
  margin-bottom: 0.5rem;
}

.total {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.total h3 {
  font-weight: normal;
  font-size: 2rem;
}

.submit {
  width: 100%;
  font-size: 1.25rem;
  padding: 1rem 0;
  background: #52b788;
  color: #fff;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  margin-top: 2rem;
}

.pay {
  margin-top: 0 !important;
  margin-bottom: 2rem !important;
}

.patron-amount {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 2rem;
}

.amount {
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  border-radius: 5px;
  padding: 0.5em;
  border: 1px solid;
  margin-bottom: 0.5rem;
  margin-right: 1rem;
  cursor: pointer;
  width: 100px;
  box-sizing: border-box;
}

.amount-wide {
  width: 150px;
}

.amount p {
  font-weight: 600;
  margin: 0;
  margin-bottom: 0.25rem;
  padding: 0.25rem 0;
}

.amount span {
}

.amount input {
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  border-radius: 5px;
  padding: 0.5em;
  border: 1px solid;
  cursor: pointer;
  width: 100%;
  box-sizing: border-box;
  margin-top: 1rem;
}

.selected-amount {
  background-color: #38413f;
  color: #fff;
  border: none;
}

.no-show {
  opacity: 0;
}

.checkout {
  width: 100%;
}

.no-margin {
  margin: 0 !important;
}

.checkout section {
}

#payment-element {
  width: 100%;
}

.click-here {
  margin: 2rem 0;
  border: 2px dotted;
  border-radius: 999px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  margin-top: 1rem;
}

.soldout {
  color: red;
}

.slashed {
  text-decoration: line-through;
  color: red;
}

.pad {
  padding-bottom: 2rem;
}

@media screen and (max-width: 1100px) {
  .row-item {
    width: 30%;
  }
}

@media screen and (max-width: 700px) {
  .shop-item {
    width: 100%;
    margin-bottom: 4rem;
  }

  .shop-item:last-child {
    margin-bottom: 0;
  }

  .row-item {
    width: 25%;
  }
}
</style>
