<template>
  <div class="navigation">
    <div class="nav-section left">
      <router-link to="/Our-Story">
        <p>Our Story</p>
      </router-link>
      <router-link to="/Family-Reunion">
        <p>Family Reunion</p>
      </router-link>
      <router-link to="/News">
        <p>News & Events</p>
      </router-link>
    </div>
    <div class="nav-section">
      <i class="fa-solid fa-book"></i>
      <router-link to="/">
        <h4>The Stephen Jenkins, Sr. Family</h4>
      </router-link>
      <i class="fa-solid fa-bars" @click="menu = true" v-show="!menu"></i>
      <i class="fa-solid fa-xmark" @click="menu = false" v-show="menu"></i>
    </div>
    <div class="nav-section right">
      <router-link to="/Photo-Album">
        <p>Photo Album</p>
      </router-link>
      <p>Contact Us</p>
      <router-link to="/Family-Login">
        <button>Family Portal</button>
      </router-link>
    </div>
  </div>
  <router-view />
  <div class="drop-menu" v-show="menu">
    <router-link to="/Our-Story" @click="menu = false">Our Story</router-link>
    <router-link to="/Family-Reunion" @click="menu = false">Family Reunion</router-link>
    <router-link to="/News" @click="menu = false">News & Events</router-link>
    <router-link to="">Shop</router-link>
    <router-link to="/Photo-Album" @click="menu = false">Photo Album</router-link>
    <router-link to="">Contact Us</router-link>
    <router-link to="/Family-Login" @click="menu = false">Family Portal</router-link>
  </div>
</template>

<script>

export default {
  name: 'App',
  page: 'landing',
  data() {
    return {
      menu: false
    }
  }
}
</script>

<style>
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f3d5b5;
  color: #38413f;
  margin: 0;
  padding: 0;
}

a {
  color: #38413f;
  text-decoration: none;
}

.navigation {
  display: flex;
  align-items: center;
  width: 100%;
  height: 8vh;
  font-family: 'Inter', sans-serif;
  background-color: transparent;
  /* box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px; */
}

.nav-section {
  width: 33.3333%;
  display: flex;
  align-items: center;
  padding: 0 3em;
  box-sizing: border-box;
}

.nav-section:nth-child(2) {
  justify-content: center;
}

.nav-section:last-child {
  justify-content: flex-end;
}

.nav-section p {
  font-size: .8em;
  cursor: pointer;
  margin-right: 2em;
  border-bottom: 1px dotted;
}

.nav-section p:hover {
  border-bottom: 1px dotted;
}

.nav-section i {
  width: 17px;
  height: 17px;
  display: none;
  align-items: center;
  justify-content: center;
  border: 1px solid;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  border-radius: 50%;
  padding: .5em;
}

.nav-section h4 {
  font-family: 'Inter', sans-serif;
  font-weight: normal;
  font-size: 1em;
  border: 1px solid;
  padding: .5em 1em;
  border-radius: 25px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  cursor: pointer;
}

.nav-section button {
  border: 1px solid;
  padding: .5em 1em;
  background-color: #fff;
  cursor: pointer;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}

.menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  height: 20px;
  width: 20px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  border: 1px solid;
  padding: 10px;
  border-radius: 50%;
}

.bar {
  width: 100%;
  height: 2px;
  background-color: #38413f;
}

.drop-menu {
  position: absolute;
  top: 8vh;
  width: 100vw;
  height: calc(100dvh - 8vh);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f3d5b5;
}

.drop-menu a {
  font-family: 'Castoro', serif;
  font-size: 2em;
  color: #38413f;
  line-height: 1.5;
  text-decoration: none;
  border-bottom: 1px dotted;
}

@media screen and (max-width: 1380px) {
  .nav-section {
    padding: 0 2em;
  }
}

@media screen and (max-width: 1150px) {
  .nav-section:nth-child(2) {
    justify-content: space-between;
  }

  .left, .right {
    display: none;
  }

  .nav-section {
    width: 100%;
    justify-content: space-between;
    padding: 0 1em;
  }

  .nav-section h4 {
    font-size: .9em;
    font-weight: normal;
    font-style: normal;
  }

  .nav-section i {
    display: flex;
  }
}
</style>
