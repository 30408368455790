/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createReunionSignUp = /* GraphQL */ `
  mutation CreateReunionSignUp(
    $input: CreateReunionSignUpInput!
    $condition: ModelReunionSignUpConditionInput
  ) {
    createReunionSignUp(input: $input, condition: $condition) {
      id
      PrimaryFullName
      PrimaryEmail
      PrimryAddress
      PrimaryPhone
      PrimaryAge
      GroupMembers
      FamilyBranch
      PatronLevel
      PatronName
      PatronEmail
      PatronDescription
      AdType
      AdName
      AdEmail
      AdDescription
      AdFileName
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateReunionSignUp = /* GraphQL */ `
  mutation UpdateReunionSignUp(
    $input: UpdateReunionSignUpInput!
    $condition: ModelReunionSignUpConditionInput
  ) {
    updateReunionSignUp(input: $input, condition: $condition) {
      id
      PrimaryFullName
      PrimaryEmail
      PrimryAddress
      PrimaryPhone
      PrimaryAge
      GroupMembers
      FamilyBranch
      PatronLevel
      PatronName
      PatronEmail
      PatronDescription
      AdType
      AdName
      AdEmail
      AdDescription
      AdFileName
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteReunionSignUp = /* GraphQL */ `
  mutation DeleteReunionSignUp(
    $input: DeleteReunionSignUpInput!
    $condition: ModelReunionSignUpConditionInput
  ) {
    deleteReunionSignUp(input: $input, condition: $condition) {
      id
      PrimaryFullName
      PrimaryEmail
      PrimryAddress
      PrimaryPhone
      PrimaryAge
      GroupMembers
      FamilyBranch
      PatronLevel
      PatronName
      PatronEmail
      PatronDescription
      AdType
      AdName
      AdEmail
      AdDescription
      AdFileName
      createdAt
      updatedAt
      __typename
    }
  }
`;
