<template>
    <div class="page-container">
        <h4>Contact Us</h4>
        <form class="card2" method="post" @submit.prevent="processPayment" ref="checkoutForm">
            <div class="form-title">
                <h5>Contact The Stephen Jenkins Family</h5>
                <p>Questions or concerns? Let us know!</p>
            </div>
            <div class="input-container">
                <div class="input-field small-input">
                    <p>Full Name <span class="astrik">*</span></p>
                    <input type="text" placeholder="Full Name" v-model="fullName" required>
                </div>
                <div class="input-field small-input">
                    <p>Email <span class="astrik">*</span></p>
                    <input type="email" placeholder="youremail@gmail.com" v-model="email" required>
                </div>
                <div class="input-field small-input">
                    <p>Phone Number </p>
                    <input type="number" placeholder="(Optional)" v-model="phone" required>
                </div>
            </div>
            <div class="text-box">
                <textarea placeholder="Enter your comments here..."></textarea>
            </div>
            <div class="submit">
                Send Message
            </div>
        </form>
    </div>
</template>
<script>
export default {

}
</script>
<style scoped>
.page-container {
    background-color: #f3d5b5;
    padding: 0 3em;
    display: flex;
    align-items: center;
    flex-direction: column;
    font-family: 'Inter', sans-serif;
}

h4 {
    display: inline-block;
    font-size: 1.5em;
    margin: 0;
    margin-top: 1em;
    margin-bottom: 2em;
    border-bottom: 1.5px solid;
    margin-bottom: 1em;
}

.card2 {
    background-color: #fff;
    border: 1px solid rgba(56, 65, 63, .35);
    box-shadow: rgb(0 0 0 / 16%) 0px 3px 6px, rgb(0 0 0 / 23%) 0px 3px 6px;
    border-radius: 5px;
    padding: 2rem 4rem;
    font-family: 'Inter', sans-serif;
    width: 80%;
    color: #38413f;
    margin-bottom: 3em;
}

.checkout-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.checkout-container .card {
    text-align: left;
    padding: 3rem;
    width: 60%;
}

.form-title {
    margin-bottom: 2rem;
}

.form-title h5 {
    font-size: 2rem;
    margin-bottom: .5em;
}

.form-title p {
    margin: 0;
}

.input-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.input-container input {
    border: 1px solid rgba(0, 0, 0, .15);
    border-radius: 5px;
    padding: .5rem;
    height: 3rem;
    width: 100%;
    font-size: 1rem;
    margin-bottom: 1rem;
    box-sizing: border-box;
    outline: none;
}

.input-field {
    width: 45%;
}

.input-field p {
    margin-bottom: .5rem;
    text-align: left;
    font-size: .8rem;
    font-weight: bold;
}

.astrik {
    font-size: .75rem;
    color: red;
}

.small-input {
    width: 31%;
}

.smaller-input {
    width: 23%;
}

.text-box {
    width: 100%;
}

textarea {
    width: 100%;
    height: 300px;
    border: 1px solid rgba(0, 0, 0, .15);
    border-radius: 5px;
    padding: .5rem;
    width: 100%;
    font-size: 1rem;
    margin-bottom: 1rem;
    box-sizing: border-box;
    outline: none;
}

.submit {
    width: 100%;
    padding: 1em 0;
    background-color: #0466c8;
    color: #fff;
    font-size: 1em;
    text-align: center;
    border-radius: 5px;
    cursor: pointer;
}
</style>