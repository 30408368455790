<template>
    <div class="news-page">
        <div class="news-header">
            <h4>News & Events</h4>
        </div>
        <ul class="news-list">
            <li class="news-card">
                <div class="card-top">
                    <img src="@/assets/bree.webp">
                </div>
                <div class="card-bottom">
                    <div class="news-title">
                        <h5>The Woman Who Took Down a Confederate Flag</h5>
                        <p>
                            Bree Newsome Bass was arrested after climbing a flagpole at the South Carolina State Capitol.
                            Now, she sees more people moved to act for similar reasons.
                        </p>
                    </div>
                    <div class="news-link">
                        <a href="https://www.nytimes.com/2020/06/14/us/politics/bree-newsome-bass-confederate-flag.html" target="_blank">View Link</a>
                    </div>
                </div>
            </li>
            <li class="news-card">
                <div class="card-top">
                    <img src="@/assets/reunion.png">
                </div>
                <div class="card-bottom">
                    <div class="news-title">
                        <h5>Stephen Jenkins Sr. Family Reunion</h5>
                        <p>
                            The time has finally come! It’s time to register for the 2022 Stephen Jenkins, Sr. Family
                            Reunion. You can also purchase your family book, purchase an ad and/or become a patron. More
                            details below:
                        </p>
                    </div>
                    <div class="news-link">
                        <a href="https://www.nytimes.com/2020/06/14/us/politics/bree-newsome-bass-confederate-flag.html" target="_blank">View Link</a>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</template>
<script>
export default {

}
</script>
<style scoped>
.news-page {
    min-height: 800px;
    background-color: #f3d5b5;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 0 3em;
    font-family: 'Inter', sans-serif;
}

.news-header {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.news-header h4 {
    font-size: 1.5em;
    margin: 0;
    margin-top: 1em;
    margin-bottom: 2em;
    border-bottom: 1.5px solid;
    margin-bottom: 1em;
}

.news-list {
    margin-top: 2em;
    list-style: none;
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.news-card {
    width: 30%;
    height: 400px;
    border: 1px solid;
    box-shadow: rgb(0 0 0 / 16%) 0px 3px 6px, rgb(0 0 0 / 23%) 0px 3px 6px;
    border-radius: 5px;
    overflow: hidden;
    margin-right: 3em;
}

.card-top {
    width: 100%;
    height: 50%;
    overflow: hidden;
}

.card-top img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.card-bottom {
    height: 50%;
    background-color: #fff;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.news-title {
    padding: 0 2em;
    padding-top: 1em;
    height: 75%;
}

.news-title p {
    overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 2; /* number of lines to show */
           line-clamp: 2;
   -webkit-box-orient: vertical;
   color: #6c757d;
}

.news-link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 25%;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background-color: #38413f;
    cursor: pointer;
}

.news-link a {
    color: #fff;
}

.card-bottom h5 {
    margin: 0;
    margin-bottom: .5em;
    font-size: 1.5em;
}

.card-bottom p {
    margin: 0;
    font-size: .9em;
    opacity: .8;
    margin-bottom: 1em;
}

@media screen and (max-width: 1500px) {
    .news-card {
        width: 45%;
    }

    .news-title {
        padding: 1em;
    }

    .news-title h5 {
        font-size: 1.2em;
        font-weight: normal;
    }

    .news-page {
        padding: 0 2em;
    }
}

@media screen and (max-width: 1150px) {

    .news-list {
        margin: 0;
        padding: 0;
    }
    .news-card {
        width: 100%;
        margin-right: 0%;
        margin-bottom: 2em;
    }
}
</style>