<template>
    <div class="page-container">
        <img src="@/assets/books.svg">
        <h5>Coming Soon!</h5>
    </div>
</template>
<script>
export default {

}
</script>
<style scoped>
.page-container {
    height: 90dvh;
    background-color: #f3d5b5;
    color: #38413f;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 3em;
    font-family: 'Inter', sans-serif;
}

img {
    width: 10%;
}

h5 {
    font-size: 2em;
    margin: 0;
    margin-bottom: .5em;
}

p {
    margin: 0;
    opacity: .8;
    font-size: .9em;
    margin-bottom: 4em;
}

input {
    border: none;
    padding: 1em .5em;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    font-size: 1.2em;
    border-radius: 5px;
    width: 600px;
    margin-bottom: 2em;
}

input:focus {
    outline: none;
}

button {
    border: none;
    background-color: #0466c8;
    color: #fff;
    border-radius: 5px;
    padding: 1em 2em;
    font-size: 1em;
    cursor: pointer;
}

@media screen and (max-width: 800px) {
    img {
        width: 50%;
    }
}
</style>