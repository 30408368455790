<template>
    <div class="page-conatiner">
        <div class="page-header">
            <img src="@/assets/profile_tree2.svg">
            <p class="name">{{ name }}</p>
            <p class="username">{{ username }}</p>
            <p class="ancestor">Harriet Jenkins Mitchell</p>
            <div class="badges">
                <div class="verified">
                    <i class="fa-regular fa-circle-check"></i>
                    <span>Verified Member</span>
                </div>
                <div class="admin">Admin</div>
                <i class="fa-solid fa-arrows-rotate refresh" @click="loadSurveys()"></i>
            </div>
        </div>
        <ul class="survey-list">
            <li v-for="(survey, index) in surveyList" :key="index">
                <p class="survey-name">{{ survey.FullName }} <span>{{ survey.Date }}</span></p>
                <div class="answer">
                    <p>Will you be making plans to attend the reunion?</p><br>
                    <span>{{ survey.Attending }}</span>
                </div>
                <div class="answer">
                    <p>How many people will be attending with you?</p><br>
                    <span>{{ survey['Number Of People'] }}</span>
                </div>
                <div class="answer">
                    <p>Age of attendees?</p><br>
                    Age 0-6: <span>{{ survey['Age 0-6'] }}</span><br>
                    Age 7-12: <span>{{ survey['Age 7-12'] }}</span><br>
                    Age 13-18: <span>{{ survey['Age 13-18'] }}</span><br>
                    Age 19-35: <span>{{ survey['Age 19-35'] }}</span><br>
                    Age 36-74: <span>{{ survey['Age 36-74'] }}</span><br>
                    Age 75+: <span>{{ survey['Age 75+'] }}</span><br>
                </div>
                <div class="answer">
                    <p>Would you consider a Thursday, July 18 arrival if additional activities are planned?</p><br>
                    <span>{{ survey['Thursday Arrival'] }}</span>
                </div>
                <div class="answer">
                    <p>Did you peruse the family website (under construction)?</p><br>
                    <span>{{ survey['Browse Website'] }}</span>
                </div>
                <div class="answer">
                    <p>Comments/Suggestion?</p><br>
                    <span>{{ survey['Comments'] }}</span>
                </div>
                <div class="answer">
                    <p>Would you be willing to work on the family website committee?</p><br>
                    <span>{{ survey['Website Committee'] }}</span>
                </div>

            </li>
        </ul>
    </div>
</template>
<script>
import { DynamoDBClient } from "@aws-sdk/client-dynamodb";
import { DynamoDBDocumentClient, ScanCommand } from "@aws-sdk/lib-dynamodb";
import { mapState } from "vuex";

const client = new DynamoDBClient({
    region: 'us-east-1', credentials: {
        accessKeyId: 'AKIAZCPG342BPOTUK4OX',
        secretAccessKey: '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'
    }
})
const docClient = DynamoDBDocumentClient.from(client)

export default {
    data() {
        return {
            surveyList: [],
        }
    },
    methods: {
        async loadSurveys() {
            this.surveyList = []
            const command = new ScanCommand({
                TableName: "reunion-questionaire",
            });

            const response = await docClient.send(command);

            response.Items.forEach(item => {
                this.surveyList.push(item)
            })

            return response;
        }
    },
    computed: mapState({
        username: state => state.username,
        name() {
            if (this.username === 'aswdesign@icloud.com') {
                return 'Andrew Wilson'
            }
            if (this.username === 'lanewsome@aol.com') {
                return 'Louis Newsome'
            }
            if (this.username === 'cmcm267@gmail.com') {
                return 'Cynthia Banks'
            }
        }
    }),
    created() {
        this.$store.commit('setUsername', JSON.parse(localStorage.getItem('username')))
        this.loadSurveys()
    },
}
</script>
<style scoped>
.page-conatiner {
    width: 100vw;
    font-family: 'Inter', sans-serif;
    box-sizing: border-box;
    padding: 0 3em;
}

.page-header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.page-header img {
    width: 100px;
    height: 100px;
    background-color: #fff;
    border: 1px solid;
    border-radius: 50%;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.name {
    font-size: 1.2em;
    font-weight: bold;
    margin-top: 1em;
    margin-bottom: .15em;
}

.username {
    font-size: .9em;
    margin-top: 0;
    margin-bottom: .25em;
}

.ancestor {
    font-size: .9em;
    margin: 0%;
    color: #6c757d;
    border-bottom: 1px dotted;
}

.badges {
    margin-top: 1em;
    display: flex;
    align-items: center;
}

.verified {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: .5em;
    border-radius: 5px;
    background-color: #4895ef;
    color: #fff;
    margin-right: 1em;
    border: 1px solid #38413f;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}

.verified i {
    margin-right: .25em;
}

.admin {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: .5em;
    border-radius: 5px;
    background-color: #ff4d6d;
    color: #fff;
    border: 1px solid #38413f;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}

.survey-list {
    list-style: none;
    width: 100%;
    margin: 0;
    padding: 0;
    margin-top: 2em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    box-sizing: border-box;
}

.survey-list li {
    width: 32%;
    box-sizing: border-box;
    background-color: #fff;
    border: 1px solid;
    box-shadow: rgb(0 0 0 / 16%) 0px 3px 6px, rgb(0 0 0 / 23%) 0px 3px 6px;
    border-radius: 5px;
    margin-bottom: 3em;
}

.survey-list li:hover {}

.survey-name {
    background-color: #38413f;
    margin: 0;
    color: #fff;
    padding: 1em;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.survey-list li button {
    margin-right: 1em;
    border: 1px solid;
    padding: .5em 1em;
    background-color: #fff;
    cursor: pointer;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}

.survey-list li p {
    font-size: 1.2em;
}

.survey-id {
    margin-right: 1em;
    color: #6c757d;
    border-bottom: 1px dotted;
}

.answer {
    padding: 0 1em;
    font-size: .9em;
}

.answer:last-child {
    margin-bottom: 1em;
}

.refresh {
    padding: .5em;
    font-size: 1.1em;
    border-radius: 5px;
    margin-left: 1em;
    border: 1px solid #38413f;
    background-color: #38413f;
    color: #80ed99;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}

.answer p {
    font-size: 1em !important;
    font-weight: 600;
    border-bottom: 1px dotted;
    display: inline-block;
}

@media screen and (max-width:1500px) {
    .survey-list li {
        width: 48%;
    }
}

@media screen and (max-width: 1000px) {
    .page-conatiner {
        padding: 0 1em;
    }

    .survey-list li {
        width: 100%;
        margin-bottom: 1em;
    }
}
</style>