import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Who from '../views/WhoWeAre.vue'
import News from '../views/News.vue'
import Reunion from '../views/FamilyReunion.vue'
import Shop from '../views/Shop.vue'
import PhotoAlbum from '../views/PhotoAlbum.vue'
import Contact from '../views/Contact.vue'
import Login from '../views/Login.vue'
import Portal from '../views/Portal.vue'
import ReunionSignUp from "@/views/ReunionSignUp.vue";
import Verify from "@/views/Verify.vue"

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/reunion-form',
    name: 'ReunionForm',
    component: ReunionSignUp
  },
  {
    path: '/Our-Story',
    name: 'Who',
    component: Who
  },
  {
    path: '/News',
    name: 'News',
    component: News
  },
  {
    path: '/Photo-Album',
    name: 'PhotoAlbum',
    component: PhotoAlbum
  },
  {
    path: '/Family-Reunion',
    name: 'Reunion',
    component: Reunion
  },
  {
    path: '/Shop',
    name: 'Shop',
    component: Shop
  },
  {
    path: '/Contact-Us',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/Family-Login',
    name: 'Login',
    component: Login
  },
  {
    path: '/Family-Portal',
    name: 'Portal',
    component: Portal,
    //beforeEnter: () => {
      //if (localStorage.getItem('username') !== null || localStorage.getItem('username') !== 'null') {
        //alert('Must be loggen in to enter.')
        //return false
      //}
    //}
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
