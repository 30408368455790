<template>
    <div class="page-container">
        <h4>Photo Album</h4>
        <div class="photos">
            <img src="@/assets/pa1.png">
            <img src="@/assets/pa2.png">
            <img src="@/assets/pa3.png">
            <img src="@/assets/pa4.png">
            <img src="@/assets/pa5.png">
            <img src="@/assets/pa6.png">
            <img src="@/assets/pa7.png">
            <img src="@/assets/pa8.png">
            <img src="@/assets/pa9.png">
            <img src="@/assets/pa10.png">
            <img src="@/assets/pa11.png">
            <img src="@/assets/pa12.png">
        </div>
    </div>
</template>
<script>
export default {

}
</script>
<style scoped>
.page-container {
    background-color: #f3d5b5;
    padding: 0 3em;
    display: flex;
    align-items: center;
    flex-direction: column;
    font-family: 'Inter', sans-serif;
}

h4 {
    display: inline-block;
    font-size: 1.5em;
    margin: 0;
    margin-top: 1em;
    margin-bottom: 2em;
    border-bottom: 1.5px solid;
    margin-bottom: 1em;
}

.photos {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

img {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    margin: 0 1em;
    margin-bottom: 2em;
    width: 300px;
    padding: 0em 1em;
    padding-top: 1em;
    background-color: #fff;
    padding-bottom: 4em;
    border-radius: 5px;
}
</style>