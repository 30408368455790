<template>
  <div class="reunion-page">
    <div class="reunion-header">
      <h5>Stephen Jenkins Sr. Family Reunion</h5>
      <p>Raleigh, NC - July 19-21, 2024</p>
      <img src="@/assets/sjslogo_update.svg" alt="" />
    </div>
    <button class="register" @click="$router.push('/reunion-form')">Click Here To Register For The Reunion</button>
    <button class="scroll-down">Scroll down to see Reunion Itinerary</button>
    <div class="hotel-container">
      <p class="hotel-text">
        <span>Hello Family,</span><br />
        We are pleased to announce the host hotel for the 19th Biennial Stephen Jenkins, Sr. Family Reunion. Early
        reunion arrivals are welcome with room availability at our group preferred rate beginning on Thursday July 18,
        2024. We have a limited # of rooms reserved in our block so book your room early.
      </p>
      <img src="@/assets/hotel.png" />
      <a
        class="click-here"
        href="https://atriumhospitalitywrnz0g.hippovideo.io/page/cary-embassy-suites_a231c18a?custom_asset_token=mZn5zFdBEzirEA5UKACDRsQDCjhVuu37f6Mxw_NlOdo"
      >
        Click here to see full video
      </a>
      <p class="hotel-address">
        <strong>Embassy Suites by Hilton Raleigh Durham Research Triangle</strong><br />
        201 Harrison Oaks Boulevard, Cary, NC 27513 <br />
        +1 919-677-1840 <br />
      </p>
      <p class="hotel-address">
        <strong>Room & Rates</strong> <br />
        Room Suite-1 King Bed or 2 Double Beds - nonsmoking <br />
        Single Occupancy - $169.00 <br />
        Double Occupancy - $169.00 <br />
        Triple Occupancy - $179.00 <br />
        Quadruple Occupancy - $189.00 <br />
      </p>
      <a class="click-here slashed"> Click here to book a room </a>
      <h2 class="soldout">Embassy Suites Hotel is now SOLD OUT</h2>
      <p>Please use the button below to purchase a room at our overflow hotel.</p>
      <a
        class="click-here"
        href="https://www.marriott.com/event-reservations/reservation-link.mi?id=1717418472186&key=GRP&app=resvlink"
      >
        Click here to book a overflow room
      </a>
    </div>
    <div class="reunion-text">
      <p>
        If you have not already done so, please take a few minutes to complete this survey to assist us in planning a
        great reunion.
      </p>
    </div>
    <form class="card2" method="post" @submit.prevent="submitSurvey()" ref="checkoutForm" v-if="survey">
      <div class="form-title">
        <h4>Reunion Survey</h4>
        <p>Help us plan next years reunion!</p>
      </div>
      <div class="question">
        <p>Please enter your name.</p>
        <input type="text" class="number" v-model="fullName" placeholder="Full Name" />
      </div>
      <div class="question">
        <p>Will you be making plans to attend the reunion?</p>
        <div class="question-check green small" @click="attending ? (attending = false) : (attending = true)">
          <span>Yes</span>
          <input type="checkbox" class="check clear" value="Yes" v-model="attending" />
        </div>
        <div class="question-check red small" @click="attending2 ? (attending2 = false) : (attending2 = true)">
          <span>No</span>
          <input type="checkbox" class="check clear" value="No" v-model="attending2" />
        </div>
      </div>
      <div class="question">
        <p>How many people will be attending with you?</p>
        <input type="number" class="number" placeholder="Number of attendees..." v-model="numOfPeople" />
      </div>
      <div class="question">
        <p>Age of attendees?</p>
        <div class="question-check">
          <span> Age 0-6</span>
          <input type="number" class="number2" placeholder="Type here..." v-model="to6" />
        </div>
        <div class="question-check">
          <span> Age 7-12</span>
          <input type="number" class="number2" placeholder="Type here..." v-model="to12" />
        </div>
        <div class="question-check">
          <span> Age 13-18</span>
          <input type="number" class="number2" placeholder="Type here..." v-model="to18" />
        </div>
        <div class="question-check">
          <span> Age 19-35</span>
          <input type="number" class="number2" placeholder="Type here..." v-model="to35" />
        </div>
        <div class="question-check">
          <span> Age 36-74</span>
          <input type="number" class="number2" placeholder="Type here..." v-model="to74" />
        </div>
        <div class="question-check">
          <span> Age 75+</span>
          <input type="number" class="number2" placeholder="Type here..." v-model="to75" />
        </div>
      </div>
      <div class="question">
        <p>Would you consider a Thursday, July 18 arrival if additional activities are planned?</p>
        <input type="text" class="text" placeholder="Type here..." v-model="thursday" />
      </div>
      <h3>Website Questionaire</h3>
      <div class="question">
        <p>Did you peruse the family website (under construction)?</p>
        <input type="text" class="text" placeholder="Type here..." v-model="website" />
      </div>
      <div class="question">
        <p>Comments/Suggestion?</p>
        <input type="text" class="text" placeholder="Type here..." v-model="comments" />
      </div>
      <div class="question">
        <p>Would you be willing to work on the family website committee?</p>
        <input type="text" class="text" placeholder="Type here..." v-model="committee" />
      </div>
      <button type="submit" class="submit-btn">Submit Survey</button>
    </form>
    <transition name="slide">
      <div class="alerts" v-show="showAlert">
        <div class="alert">
          <div class="alert-area">
            <p>{{ alerts }}</p>
          </div>
          <div class="alert-area" @click="showAlert = false">
            <div class="close-btn"><i class="fa-solid fa-xmark"></i>Close</div>
          </div>
        </div>
      </div>
    </transition>
    <div class="itinerary card2">
      <header>
        <h1>Bringing Light and Life to the Jenkins Family Legacy</h1>
        <h2>Reunion Itinerary</h2>
        <p>Embassy Suites by Hilton Raleigh Durham Research Triangle</p>
        <p>201 Harrison Oaks Boulevard, Cary, NC 27513</p>
      </header>

      <div class="day">
        <h3>Thursday</h3>
        <p>Early Arrivals – Enjoy local attractions on your own.</p>
        <p>6:00 – 7:30 <b>PM Complimentary Happy Hour for registered hotel guests – Hotel Bar Area</b></p>
      </div>

      <div class="day">
        <h3>Friday</h3>
        <p>6:30 – 10:30 AM <b>Complimentary Breakfast for registered hotel guests – Hotel Cafeteria</b></p>
        <p>4:00 – 8:00 PM Registration - <b>Atrium I</b></p>
        <p>Dinner on your own – A variety of restaurants are conveniently located near the hotel.</p>
        <p>6:00 – 7:30 PM <b>Complimentary Happy Hour for registered hotel guests - Hotel Bar Area</b></p>
        <p>8:00 PM <b>Encore Presentation of the 30th Anniversary Jenkins Legacy Video – Chimney Rock Room</b></p>
        <p>8:30 PM Jazz Reception – Games, Cards, etc. – <b>Chimney Rock Room</b></p>
      </div>

      <div class="day">
        <h3>Saturday</h3>
        <p>7:30 – 10:30 AM <b>Complimentary Breakfast for registered hotel guests – Hotel Cafeteria</b></p>
        <p>11:00 AM – 4:00 PM Registration – <b>Atrium I</b></p>
        <p>11:00 – 1:30 PM Family Lunch at historic <b>Pullen Park - 520 Ashe Ave, Raleigh, NC 27606 - Shelter 4</b></p>
        <p>
          <b>Pullen Park is the first public park in North Carolina, the 5th oldest operating amusement park in the U.S.</b>
        </p>
        <p><b>(Rides available: Carousel, Train, Pedal Boats, Kiddie Boats - $2.00 per person)</b></p>
        <p>2:00 PM Return to hotel – Hotel pool open</p>
        <p>2:00 – 2:30 PM Special Family Presentation – <b>Cape Hatteras Room</b></p>
        <p>
          2:30 – 4:00 PM <b>Book Signing Event</b> – Cousin Dr. Shannon Eaves, PhD. <b>Sexual Violence and American Slavery: The</b>
          <b>Making of a Rape Culture in the Antebellum South (UNC Press) - Cape Hatteras Room</b>
        </p>
        <p>6:00 – 7:30 PM <b>Complimentary Happy Hour for registered hotel guests – Hotel Bar Area</b></p>
        <p>6:30 PM Banquet – Music – Social – Family Photos - <b>Triangle Ballroom</b></p>
      </div>

      <div class="day">
        <h3>Sunday</h3>

        <p>7:30 – 10:30 AM <b>Complimentary Breakfast for registered hotel guests – Hotel Cafeteria</b></p>
        <p>10:00 AM Homily – <b>Smith Room</b></p>
        <p>11:00 AM Hotel Check Out & Departures</p>
      </div>
    </div>
    <div class="book-event card2">
        <header>
            <h1>A BOOK SIGNING EVENT</h1>
    <p>With Cousin Shannon Eaves, Ph.D.</p>
        </header>

    <div class="imgs">
        <img src="@/assets/booke1.png" alt="">
        <img src="@/assets/booke2.jpeg" alt="">
    </div>
    
    <h2>Sexual Violence and American Slavery: The Making of a Rape Culture in the Antebellum South</h2>
    <p>UNC Press link: <a href="https://uncpress.org/book/9781469678818/sexual-violence-and-american-slavery/" target="_blank">https://uncpress.org/book/9781469678818/sexual-violence-and-american-slavery/</a></p>
    
    <p>UNC Press 30% Discount Code: <strong>01UNCP30</strong></p>
    
    <h3>CONVERSATION ALONG WITH Q & A WITH AUTHOR</h3>
    <h3>PARTICIPATE IN DRAWINGS FOR FREE BOOK GIVE-A-WAYS</h3>
    <p>Saturday, June 20, 2024 @ 2:30 PM</p>
    
    <h4>STEPHEN JENKINS, SR. 19TH BIENNIAL FAMILY REUNION</h4>
    <p>Embassy Suites by Hilton Raleigh-Durham/Research Triangle</p>
    <p>Cape Hatteras Room</p>
    </div>
  </div>
</template>
<script>
import { DynamoDBClient } from "@aws-sdk/client-dynamodb";
import { PutCommand, DynamoDBDocumentClient } from "@aws-sdk/lib-dynamodb";

const client = new DynamoDBClient({
  region: "us-east-1",
  credentials: {
    accessKeyId: "AKIAZCPG342BPOTUK4OX",
    secretAccessKey: "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",
  },
});
const docClient = DynamoDBDocumentClient.from(client);

export default {
  data() {
    return {
      mealType: ["Chicken", "Fish", "Vegetarian"],
      BanquetType: ["Yes", "No"],
      selectMeal: false,
      selectBanquet: false,
      banquetSelection: "",
      mealSelection: "",
      age: "",
      fullName: "",
      attending: "",
      attending2: "",
      numOfPeople: "",
      to12: "",
      to18: "",
      to35: "",
      to6: "",
      to74: "",
      to75: "",
      thursday: "",
      website: "",
      comments: "",
      committee: "",
      alerts: "",
      showAlert: false,
      video: "",
      survey: false,
    };
  },
  methods: {
    async submitSurvey() {
      const id = Math.floor(Math.random() * 1000) + 1;
      const command = new PutCommand({
        TableName: "reunion-questionaire",
        Item: {
          ID: id.toString(),
          FullName: this.fullName,
          Attending: this.attending !== "" ? "Yes" : "No",
          "Number Of People": this.numOfPeople,
          "Age 0-6": this.to6.toString(),
          "Age 7-12": this.to12.toString(),
          "Age 13-18": this.to18.toString(),
          "Age 19-35": this.to35.toString(),
          "Age 36-74": this.to74.toString(),
          "Age 75+": this.to75.toString(),
          "Thursday Arrival": this.thursday,
          "Browse Website": this.website,
          Comments: this.comments,
          "Website Committee": this.committee,
          Date: new Date().toLocaleDateString(),
        },
      });

      const response = await docClient.send(command);

      if (response.$metadata.httpStatusCode === 200) {
        this.alerts = "Survey successfully submitted.";
        this.showAlert = true;
      }

      this.id = "";
      this.thursday = "";
      this.fullName = "";
      this.attending = "";
      this.numOfPeople = "";
      this.to12 = "";
      this.to18 = "";
      (this.to35 = ""), (this.to6 = "");
      this.to74 = "";
      this.to75 = "";
      this.website = "";
      this.comments = "";
      this.committee = "";
      return response;
    },
  },
  computed: {
    formTotal() {
      let total = 0;

      if (this.age === "") {
        total = 0;
      }

      if (this.age < 7) {
        total = 0;
      }

      if (this.age > 6 && this.age < 13) {
        total = 55;
      }

      if (this.age > 12 && this.age < 19) {
        total = 65;
      }

      if (this.age > 18 && this.age < 75) {
        total = 130;
      }

      if (this.age > 74) {
        total = 120;
      }

      return total;
    },
  },
};
</script>
<style scoped>
.reunion-page {
  background-color: #f3d5b5;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 3em;
  font-family: "Inter", sans-serif;
  box-sizing: border-box;
}

.reunion-header {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.reunion-header h5 {
  font-family: "Inter", serif;
  font-size: 1.5em;
  margin: 0;
  margin-top: 1em;
  margin-bottom: 2em;
  border-bottom: 1px dotted;
  margin-bottom: 1em;
}

.reunion-header p {
  font-size: 1.2em;
  margin: 0;
}

.reunion-header img {
  width: 400px;
}

.place-date {
  display: flex;
  align-items: center;
}

.place-date p {
  margin: 0;
  margin-right: 2em;
}

.register {
  background: #fff;
  border: 1px solid;
  border-radius: 5px;
  font-size: 1.5rem;
  padding: 0.5rem;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}

.hotel-container {
  width: 100dvw;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.hotel-container img {
  max-width: 800px;
  border-radius: 5px;
  box-shadow: rgb(0 0 0 / 16%) 0px 3px 6px, rgb(0 0 0 / 23%) 0px 3px 6px;
}

.click-here {
  margin: 2rem 0;
  border: 2px dotted;
  border-radius: 999px;
  padding: 0.5rem 1rem;
  cursor: pointer;
}

.soldout {
  color: red;
}

.slashed {
  text-decoration: line-through;
  color: red;
}

.hotel-container p {
  text-align: center;
}

.hotel-text {
  max-width: 800px;
  padding: 0 3rem;
  font-size: 0.9rem;
  line-height: 1.5;
  margin: 2rem 0;
  text-align: justify !important;
}

.hotel-text span {
  width: 100%;
  display: flex;
  place-content: center;
  font-size: 1.2rem;
}

.hotel-address {
  width: 80%;
  line-height: 1.5;
  font-size: 0.9rem;
}

.line {
  width: 100%;
  height: 1.5px;
  background: #38413f;
}

.reunion-text {
  margin-top: 2em;
  width: 70%;
  text-align: center;
  line-height: 1.5em;
}

.reunion-text h6 {
  font-size: 1em;
  margin: 0;
  margin-bottom: 1em;
  text-align: center;
  font-weight: normal;
}

.reunion-text p {
  margin-bottom: 2em;
  font-size: 0.9em;
}

.card2 {
  background-color: #fff;
  border: 1px solid;
  box-shadow: rgb(0 0 0 / 16%) 0px 3px 6px, rgb(0 0 0 / 23%) 0px 3px 6px;
  border-radius: 5px;
  padding: 2rem 4rem;
  font-family: "Inter", sans-serif;
  width: 80%;
  color: #38413f;
  margin-bottom: 3em;
}

.checkout-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.checkout-container .card {
  text-align: left;
  padding: 3rem;
  width: 60%;
}

.form-title {
  padding: 0.5em 0;
  margin-bottom: 2rem;
}

.form-title h4 {
  font-size: 2rem;
  margin-bottom: 0.25em;
}

.form-title p {
  margin: 0;
}

.question {
  width: 100%;
  margin-bottom: 0.5em;
  box-sizing: border-box;
}

.question p {
  margin-right: 2em;
}

h3 {
  text-decoration: underline;
}

.question .text {
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  border-radius: 5px;
  padding: 0.5em;
  font-size: 1.2em;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid;
}

.question .text:focus {
  outline: none;
}

.question .number {
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  border-radius: 5px;
  padding: 0.5em;
  border: 1px solid;
  font-size: 1.2em;
  width: 100%;
  box-sizing: border-box;
}

.question .question-check {
  margin-bottom: 0.5em;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  border-radius: 5px;
  padding: 0.5em;
  border: 1px solid;
}

.small {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100px;
}

.question-check span {
  margin-right: 1em;
}

.input-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.input-container input {
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  padding: 0.5rem;
  height: 3rem;
  width: 100%;
  font-size: 1rem;
  margin-bottom: 1rem;
  box-sizing: border-box;
  outline: none;
}

.input-field {
  width: 45%;
}

.input-field p {
  margin-bottom: 0.5rem;
  text-align: left;
  font-size: 0.8rem;
  font-weight: bold;
}

.astrik {
  font-size: 0.75rem;
  color: red;
}

.small-input {
  width: 31%;
}

.smaller-input {
  width: 23%;
}

.tickets {
  width: 100%;
  background-color: #f2f2f2;
  padding: 1em 0;
  margin-bottom: 2em;
}

.tickets ul {
  width: 100%;
  list-style: none;
}

.tickets-text {
  text-align: center;
  font-size: 1.2em;
  text-decoration: underline;
}

.ticket-row {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ticket-item {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-right: 2em;
}

.forminput {
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  padding: 0.5rem;
  height: 3rem;
  width: 100%;
  font-size: 1rem;
  margin-bottom: 1rem;
  box-sizing: border-box;
  outline: none;
}

.type-container {
  background-color: #fff;
  box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  margin-bottom: 1em;
}

.select-type {
  padding: 0.8em 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
  cursor: pointer;
}

.select-type p {
  margin: 0;
  margin-right: 1em;
}

.down-arrow {
  width: 10px;
  height: 10px;
  border-left: 1px solid rgba(0, 0, 0, 0.75);
  border-bottom: 1px solid rgba(0, 0, 0, 0.75);
  transform: rotate(-45deg) translate(4px, 0);
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.dropdown-item {
  padding: 0.5em 1em;
  background-color: #e9ecef;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  display: flex;
  align-items: center;
}

.dropdown-item input {
  margin-right: 1em;
  cursor: pointer;
}

.dropdown-item p {
  margin: 0;
}

.total {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.1rem;
  margin-bottom: 2rem;
  padding-top: 1rem;
}

.ticket-detail {
  opacity: 0.7;
  font-size: 1em;
}

.to-cart {
  width: 100%;
}

.to-cart button {
  width: 100%;
  padding: 1em 0;
  color: #fff;
  background-color: #0466c8;
  box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  font-size: 1.2em;
}

.submit-btn {
  border: 1px solid;
  width: 100%;
  font-family: inherit;
  padding: 1em;
  margin-top: 2em;
  background-color: #38413f;
  color: #fff;
  font-size: 1em;
  border-radius: 5px;
  cursor: pointer;
}

.number2 {
  border: 0;
  font-size: 1em;
}

.alerts {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  box-sizing: border-box;
  z-index: 1000;
}

.alert {
  background-color: #52b788;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.alert-area {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}

.close-btn {
  color: rgba(255, 255, 255, 0.75);
  text-align: center;
  padding: 0.25em 0.5em;
  background-color: rgba(0, 0, 0, 0.25);
  cursor: pointer;
  border-radius: 5px;
}

.alert i {
  margin-right: 0.25em;
}

.alert-area:last-child {
  text-align: center;
  padding: 0.5em 0;
  background-color: rgba(0, 0, 0, 0.25);
  cursor: pointer;
}

.alerts p {
  margin: 0;
  padding: 0.5em;
  text-align: center;
}

.alerts span {
  color: rgba(255, 255, 255, 0.75);
  text-align: center;
  padding: 0.25em 0.5em;
  background-color: rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  cursor: pointer;
}

.slide-enter-active {
  transition: all 0.3s ease-in;
}

.slide-leave-active {
  transition: all 0.3s ease-out;
}

.slide-enter-from,
.slide-leave-to {
  transform: translateY(-160px);
}

.clear {
  background-color: rgba(0, 0, 0, 0.25) !important;
}

.green {
  background-color: #52b788 !important;
  color: #fff;
  border: 1px solid #38413f !important;
}

.red {
  background-color: #f25c54 !important;
  color: #fff;
  border: 1px solid #38413f !important;
}

@media screen and (max-width: 850px) {
  .hotel-container img {
    width: 300px;
  }
}

@media screen and (max-width: 1150px) {
  .reunion-header h5 {
    font-size: 1em;
    font-weight: normal;
    border-bottom: 1px dotted;
    margin-bottom: 0.5em;
  }

  .reunion-header p {
    margin: 0;
    font-size: 0.9em;
  }

  .reunion-text {
    width: 100%;
    text-align: justify;
  }

  .reunion-text h6 {
    margin-bottom: 0.5em;
  }

  .reunion-text p {
    margin: 0;
    margin-bottom: 2em;
  }

  .card2 {
    padding: 0 2em !important;
    padding-bottom: 2em !important;
    width: 100%;
  }

  .form-title {
    margin: 0;
  }

  .form-title h4 {
    font-weight: normal;
    font-size: 1.5em;
  }

  .form-title p {
    color: #6c757d;
    font-size: 0.9em;
  }

  .question {
    display: inline-block;
    box-sizing: border-box;
  }

  .question .text {
  }

  h3 {
    font-weight: normal;
  }

  .question-check {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0.5em 0;
  }

  .number {
    width: 94%;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px !important;
    font-size: 1em !important;
  }

  .number input {
    box-sizing: border-box;
  }

  .number2 {
    width: 100%;
  }
}

.itinerary header {
    text-align: center;
    margin-bottom: 2rem;
}

.scroll-down {
    background-color: transparent;
    margin-top: 2rem;
    border: none;
    border-bottom: 2px dotted;
    font-size: large;
}

.imgs {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.imgs img {
    width: 45%;
    max-width: 400px;
    padding: 1rem;
}

.book-event header {
    text-align: center;
}

</style>
