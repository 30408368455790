<template>
    <div class="who-page">
        <div class="who-header">
            <h4>The Jenkins Reunion Executive Committee</h4>
            <div class="committee-imgs">
                <div class="committee-box">
                    <img src="@/assets/committee-1.png">
                    <p>Cordia W. Hall</p>
                    <span>Mary Jenkins Mitchell Family</span>
                </div>
                <div class="committee-box">
                    <img src="@/assets/committee-2.png">
                    <p>Florence Peele Hollomon</p>
                    <span>Charles Jenkins Family</span>
                </div>
                <div class="committee-box">
                    <img src="@/assets/committee-3.png">
                    <p>Legolia Peele McGlohon</p>
                    <span>Charles Jenkins Family</span>
                </div>
                <div class="committee-box">
                    <img src="@/assets/committee-4.png">
                    <p>Evelyn H. Monk</p>
                    <span>Harriet Jenkins Mitchell Family</span>
                </div>
                <div class="committee-box">
                    <img src="@/assets/committee-5.png">
                    <p>Gladys Joyner Lister</p>
                    <span>Charles Jenkins Family</span>
                </div>
                <div class="committee-box">
                    <img src="@/assets/committee-6.png">
                    <p>Rebecca H. White</p>
                    <span>Perry Jenkins Family</span>
                </div>
                <div class="committee-box">
                    <img src="@/assets/committee-7.png">
                    <p>Clarence Shaw Newsome</p>
                    <span>Perry Jenkins Family</span>
                </div>
                <div class="committee-box">
                    <img src="@/assets/committee-8.png">
                    <p>Thomas Hollomon</p>
                    <span>James Jenkins Family</span>
                </div>
                <div class="committee-box">
                    <img src="@/assets/committee-9.png">
                    <p>Lorraine Hollomon Williams</p>
                    <span>James Jenkins Family</span>
                </div>
                <div class="committee-box">
                    <img src="@/assets/committee-10.png">
                    <p>Herman Sebastian Jenkins</p>
                    <span>James Jenkins Family</span>
                </div>
                <div class="committee-box">
                    <img src="@/assets/committee-11.png">
                    <p>Annie Nell Jenkins Whitfield</p>
                    <span>James Jenkins Family</span>
                </div>
                <div class="committee-box">
                    <img src="@/assets/committee-12.png">
                    <p>Mollie Freeman James</p>
                    <span>James Jenkins Family</span>
                </div>
                <div class="committee-box">
                    <img src="@/assets/committee-13.png">
                    <p>Edward N. Smith</p>
                    <span>Ratchell Jenkins Hollomon Family</span>
                </div>
                <div class="committee-box">
                    <img src="@/assets/committee-14.png">
                    <p>Charles Jenkins</p>
                    <span>Stephen Jenkins, Jr Family</span>
                </div>
                <div class="committee-box">
                    <img src="@/assets/committee-15.png">
                    <p>Mildred Simons</p>
                    <span>Stephen Jenkins, Jr Family</span>
                </div>
                <div class="committee-box">
                    <img src="@/assets/committee-16.png">
                    <p>Clarence Jenkins</p>
                    <span>Stephen Jenkins, Jr Family</span>
                </div>
            </div>
            <span>"Lives of great men all remind us, we can make our lives sublime. And, departing, leave behind us,
                footprints on the sands of time."</span>
        </div>
        <div class="who-text">
            <p>After five years of extensive research, the documentation of over 200 years of history was presented
                to the descendants of Stephen Jenkins, Sr. in July, 1988 at a family reunion held in Ahoskie, North
                Carolina. The purpose of that first reunion was to revitalize the Jenkins heritage and bring the
                family legacy into focus.</p>
            <p>The genealogical study of the Stephen Jenkins Sr. family was launched with the documentation of
                Wright Jenkins, the family patriarch, a slave born approximately 1785 in Hertford County, North
                Carolina. In the 1700 and early 1800 censuses, slaves were not recorded in the census records as
                they were considered chattel property, represented only by numbers. Wright Jenkins Sr.’s only known
                child was Stephen Jenkins, Sr. Stephen’s birth date and death date are recorded in the federal
                census and in the Hertford County, NC archives. Stephen Jenkins, Sr. is, therefore, the namesake of
                the family reunion and family association.</p>
            <p>Stephen Jenkins, Sr. was born in 1810 on a slave farm near Murfreesboro, NC. In 1837 he was “allowed”
                to marry Louvenia Hayes, who was also a slave. They were blessed with nine children, eight lived to
                become adults, married, and had families of their own. All of their children attended “common
                school” during slavery.</p>
            <p>After the civil war, Stephen Jenkins, Sr. had the means to purchase several tracts of land on the
                main highway which ran through the county. On this land, he built his family home, raised livestock,
                and farmed. His sons, Charles, Perry and Wright married and established family homes of their own.
                Two sons, James and Stephen, Jr. and their families lived at the home site along with a daughter,
                Rachael, and her family, who moved in after the death of her mother. Two daughters, Harriet and
                Mary, were slaves on a farm in Bertie County, NC prior to the Civil War. When the Civil War ended,
                Harriet moved with her family to a home near her father and siblings. Mary remained with her husband
                and family in Bertie County. The youngest child, Lawrence, never married and died at an early age.
            </p>
            <p>Stephen Jenkins Sr.’s sons, “the Jenkins brothers,” were among a group of citizens who purchased land
                and constructed the first building for what is now known as the New Ahoskie Missionary Baptist
                Church. Several of the Jenkins siblings were among the first Deacons and Missionaries of the church,
                and son James, who became a minister, delivered the first sermon in the new church in 1869.</p>
            <p>For a man who spent 55 years of his life in bondage, and whose children were born into bondage;
                Stephen Jenkins, Sr. managed a remarkable life. He was a landowner, farmer, father, and church
                leader who understood the value of education. His eighth child, Stephen, Jr. attended Shaw
                University in Raleigh, NC and studied under Henry Martin Tupper, founder of Shaw University. One of
                his grandsons attended medical school at Shaw University and another left North Carolina in 1900 to
                attend law school.</p>
            <p>The history of the Stephen Jenkins, Sr. family is documented in two copyrighted publications; The
                Ancestry of The Stephens Jenkins Senior Family, Volume I (1785 – 1988) and Volume II (1988 – 2014).
                Copies of these publications are located in the US Library of Congress and the North Carolina State
                Archives. The publications document the story of a proud, dynamic family with a profound belief in
                education and religion and with a deeply engrained sense of the cultural aspects of life.</p>
            <p>The Stephen Jenkins, Sr. family heritage has been traced to the Fula People of Guinea Bissau, Africa.
                His descendants, along with their spouses, have distinguished themselves in virtually every field of
                human endeavor. In the Arts and Sciences; in education, law, business, religion, healthcare,
                athletics, military service, social activism, and philanthropy. Nearly 2,000 descendants of Stephen
                Jenkins, Sr. have been documented to date, spanning nine generations, residing in over 25 states and
                several countries, over more than 230 years. Biennial reunions of the Stephen Jenkins, Sr. family
                have been held since 1988. The 15th reunion will be held July 15 -17, 2016 in Philadelphia,
                Pennsylvania.</p>
        </div>
    </div>
</template>
<script></script>
<style scoped>
.who-page {
    overflow-y: scroll;
    background-color: #f3d5b5;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 3em;
}

.who-header {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.who-header h4 {
    font-family: 'Inter', serif;
    font-size: 1.5em;
    margin: 0;
    margin-top: 1em;
    margin-bottom: 2em;
    border-bottom: 1.5px solid;
}

.who-header span {
    font-family: 'Inter', sans-serif;
    font-style: italic;
    opacity: .7;
    font-size: 1em;
}

.committee-imgs {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 2em;
}

.committee-box {
    padding: 1em;
    margin-bottom: 2em;
    text-align: center;
}

.committee-box img {
    width: 100%;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    border: 1px solid;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.committee-box p {
    font-family: 'Inter', sans-serif;
    font-size: .8em;
    margin: 0;
    margin-top: 1em;
    margin-bottom: .25em;
}

.committee-box span {
    font-size: .7em;
}

.who-text {
    margin: 3em 0;
    font-family: 'Inter', sans-serif;
    text-align: justify;
    padding: 1em 2em;
    line-height: 2em;
    background-color: #fff;
    border: 1px solid;
    box-shadow: rgb(0 0 0 / 16%) 0px 3px 6px, rgb(0 0 0 / 23%) 0px 3px 6px;
    border-radius: 5px;
}

@media screen and (max-width: 1150px) {
    .who-page {
        padding: 0 1em;
    }
    .who-header h4 {
        font-family: 'Inter', sans-serif;
        font-size: 1em;
        margin-bottom: 1em;
    }
}
</style>